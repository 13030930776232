import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';
import { Form } from 'formik';


export const ChangeOrderStopPriceNoAddressContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    min-width: 550px;
    max-width: 550px;
`;

export const ChangeOrderStopPriceNoAddressForm = styled(Form)`
  min-width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
`;

export const ChangeOrderStopPriceNoAddressTitle = styled(Typography).attrs({
    variant: 'h4'
})`
    text-align: center;
`;
