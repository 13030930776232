import React from 'react';
import { PurchaseStopPriceRowContainer, PurchaseStopPriceRowRangeContainer } from './style';
import { Translate } from '../../../../../../internationalization/translate';
import FormikInputCurrency from '../../../../../../components/Formik/FormikInputCurrency';


const ZonePurchaseStopPriceRow = ({id}: any) => {

    return (
        <PurchaseStopPriceRowContainer>
            <PurchaseStopPriceRowRangeContainer>
                <FormikInputCurrency
                    name={`from_${id}`}
                    variant={'outlined'}
                    label={Translate.fromPrice}
                />
                <FormikInputCurrency
                    name={`to_${id}`}
                    variant={'outlined'}
                    label={Translate.toPrice}
                />
            </PurchaseStopPriceRowRangeContainer>
            <FormikInputCurrency
                name={`price_${id}`}
                variant={'outlined'}
                label={Translate.itemPrice}
            />
        </PurchaseStopPriceRowContainer>
    );
};


export default ZonePurchaseStopPriceRow;