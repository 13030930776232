import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';

export const ClearNotificationDialogContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 600px;
    padding-bottom: 20px;
`;


export const ClearNotificationDialogText = styled(Flex)`
    max-width: 90%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';
`;

export const ClearNotificationDialogSubText = styled(Flex)`
    padding-top: 10px;
    text-align: center;
    font: normal normal normal 16px/22px 'Readex Pro';
    font-style: italic;
`;
