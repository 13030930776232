import React, { useCallback, useContext } from 'react';
import { ClearItemButton, OrderFreeItemContainer, OrderFreeItemRow, OrderItemPurchaseCheckbox } from './style';
import { Translate } from 'internationalization/translate';
import FormikTextField from 'components/Formik/FormikTextField';
import FormikInputCurrency from 'components/Formik/FormikInputCurrency';
import ClearIcon from '@mui/icons-material/Clear';

import { useFormikContext } from 'formik';
import OrderFreeContext from '../../context/context';
import AddressSearch from '../../../../../../../components/Autocomplete/AddressSearch';
import PickUpValue from './PickUpValue';
import MapPin from '../../components/MapPinDrop';

const OrderFreeItem = ({ item }: any) => {

    const { removeItem, updateItem } = useContext(OrderFreeContext);
    const { unregisterField, setFieldValue } = useFormikContext() as any;

    const handlerRemoveItem = useCallback(() => {
        if (!item?.id) return;
        removeItem(item.id);
        unregisterField(`${item.id}_locationName`);
        unregisterField(`${item.id}_description`);
        unregisterField(`${item.id}_address`);
        unregisterField(`${item.id}_pickUpValueNum`);
        unregisterField(`${item.id}_pickUpValueMask`);
        unregisterField(`${item.id}_amount`);
        unregisterField(`${item.id}_isPurchase`);
        setFieldValue(`${item.id}_locationName`, undefined as any);
        setFieldValue(`${item.id}_description`, undefined as any);
        setFieldValue(`${item.id}_address`, undefined as any);
        setFieldValue(`${item.id}_pickUpValueNum`, undefined as any);
        setFieldValue(`${item.id}_pickUpValueMask`, undefined as any);
        setFieldValue(`${item.id}_amount`, undefined as any);
        setFieldValue(`${item.id}_isPurchase`, undefined as any);
    }, [removeItem, item, unregisterField, setFieldValue]);

    const updateTimePicker = useCallback((field: string, data: boolean) => {
        updateItem(item.id, {
            [field]: data
        });
    }, [updateItem, item]);

    return (
        <OrderFreeItemContainer>
            <ClearItemButton onClick={handlerRemoveItem}>
                <ClearIcon />
            </ClearItemButton>
            <OrderFreeItemRow>
                <AddressSearch
                    isFormik
                    name={`${item.id}_address`}
                    label={Translate.defineOrderItemAddress}
                    showSwitch={false}
                    fullWidth={false}
                />
                <MapPin realField={`${item.id}_address`} />
            </OrderFreeItemRow>
            <OrderItemPurchaseCheckbox
                name={`${item.id}_isPurchase`}
                label={Translate.defineOrderItemIsPurchase}
                type={'checkbox'}
                fullWidth
            />
            <FormikTextField
                name={`${item.id}_description`}
                variant={'outlined'}
                label={Translate.defineOrderItemDescription}
                rows={3}
                multiline
            />
            <PickUpValue id={item.id} updateItem={updateTimePicker} />
            <FormikInputCurrency
                name={`${item.id}_amount`}
                variant={'outlined'}
                label={Translate.defineOrderItemAmount}
            />
        </OrderFreeItemContainer>
    );
};

export default OrderFreeItem;