import styled from 'styled-components';
import { Flex } from 'assets/style';


export const OrderBiddingCreateContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 520px;
    padding: 0 20px;
`;

export const OrderBiddingCreateText = styled(Flex)`
    max-width: 90%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';

`;
