

export enum SETTINGS_KEYS {
    SHIFT_SCHEDULING='SHIFT_SCHEDULING',
    SHIFT_START_AMOUNT='SHIFT_START_AMOUNT',
    BIDDING_TIME = 'BIDDING_TIME',
    BIDDING_AUTO_START_TIME = 'BIDDING_AUTO_START_TIME',
    ORDER_SCHEDULED_PENDING_TIME = 'ORDER_SCHEDULED_PENDING_TIME',

    ORDER_STOP_PRICE_NO_ADDRESS = 'ORDER_STOP_PRICE_NO_ADDRESS',
    ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS = 'ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS',
    ORDER_ZONE_1 = 'ORDER_ZONE_1',
    ORDER_ZONE_2 = 'ORDER_ZONE_2',
    ORDER_ZONE_3 = 'ORDER_ZONE_3',
}