import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useCallback, useMemo } from 'react';
import {
    ShiftActionButton,
    ShiftStartedActionMenu,
    ShiftStartedActionMenuItem,
    ShiftStartedActionMenuItemIcon
} from './style';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';
import { ListItemText } from '@mui/material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ConditionalRendering from '../../../../../../../components/Utils/ConditionalRendering';
import {
    SHIFT_SCHEDULING_PREVIEW_EVENTS,
    SHIFT_SCHEDULING_STATUS
} from '../../../../../view/SchedulingBoard/d';
import { ORDER_STATUS } from '../../../../../../Orders/d';


const ButtonActionShiftStarted = ({item}: any) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const [isCanStop, isCanResume, driverId, vehicleId, isEnded, isAllowedEnding, isNotStarted] = useMemo(()=> [
        item.status !== 0 && item.status !== SHIFT_SCHEDULING_STATUS.TEMP_STOP && item.status !== SHIFT_SCHEDULING_STATUS.TEMP_NOT_WORK,
        item.status !== 0 && item.status === SHIFT_SCHEDULING_STATUS.TEMP_STOP,
        item[`${item.shiftId}_driverId_${item.id}`],
        item[`${item.shiftId}_vehicleId_${item.id}`],
        item.status === SHIFT_SCHEDULING_STATUS.END || item.status === SHIFT_SCHEDULING_STATUS.DISP_END,
        item.status !== 0 && !item?.orders?.filter((x: any)=> [ORDER_STATUS.PENDING, ORDER_STATUS.ACCEPTED, ORDER_STATUS.ASSIGNED, ORDER_STATUS.PICK_UP, ORDER_STATUS.READY].includes(x.status))?.length,
        item.status === 0
    ],[item]);

    return (
        <>
            <ConditionalRendering condition={!isEnded}>
                <ShiftActionButton
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon/>
                </ShiftActionButton>
            </ConditionalRendering>
            <ShiftStartedActionMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ConditionalRendering condition={isCanStop}>
                    <ShiftStartedActionMenuItem
                        disableRipple
                        onClick={handleClose}
                        data-action={SHIFT_SCHEDULING_PREVIEW_EVENTS.TEMP_STOP}
                        data-action-param={JSON.stringify({ guid: item.guid, id: item.id, driverId, vehicleId, status: item.status })}
                    >
                        <ShiftStartedActionMenuItemIcon>
                            <PauseCircleOutlinedIcon />
                        </ShiftStartedActionMenuItemIcon>
                        <Translation use={Translate.pause} as={ListItemText}/>
                    </ShiftStartedActionMenuItem>
                </ConditionalRendering>
                <ConditionalRendering condition={isCanResume}>
                    <ShiftStartedActionMenuItem
                        disableRipple
                        onClick={handleClose}
                        data-action={SHIFT_SCHEDULING_PREVIEW_EVENTS.TEMP_CAN_RESUME}
                        data-action-param={JSON.stringify({  guid: item.guid, id: item.id, driverId, vehicleId, status: item.status })}
                    >
                        <ShiftStartedActionMenuItemIcon>
                            <ChangeCircleOutlinedIcon/>
                        </ShiftStartedActionMenuItemIcon>
                        <Translation use={Translate.resume} as={ListItemText}/>
                    </ShiftStartedActionMenuItem>
                </ConditionalRendering>
                <ConditionalRendering condition={isNotStarted || isAllowedEnding}>
                    <ShiftStartedActionMenuItem
                        disableRipple
                        onClick={handleClose}
                        data-action={SHIFT_SCHEDULING_PREVIEW_EVENTS.END_SHIFT}
                        data-action-param={JSON.stringify({  guid: item.guid, id: item.id, driverId, vehicleId, status: item.status })}
                    >
                        <ShiftStartedActionMenuItemIcon>
                            <StopCircleIcon/>
                        </ShiftStartedActionMenuItemIcon>
                        <Translation use={Translate.stop} as={ListItemText}/>
                    </ShiftStartedActionMenuItem>
                </ConditionalRendering>
            </ShiftStartedActionMenu>
        </>
    );
};


export default ButtonActionShiftStarted;