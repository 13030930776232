import React, { useCallback, useContext } from 'react';
import {
    CancelButton,
    ConfirmButton,
    PreviewOrderItemEditPriceButtons,
    PreviewOrderItemEditPriceContainer,
    PreviewOrderItemEditPriceForm
} from './style';
import { Check, Clear } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Translate } from '../../../../../../../internationalization/translate';
import UiDataContext from '../../../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { queryClient } from '../../../../../../../core/root/ReactQueryProvider';
import FormikInputCurrency from '../../../../../../../components/Formik/FormikInputCurrency';
import useUpdateOrderItemAmountMutation
    from '../../../../../../../core/api/hooks/mutations/order/useUpdateOrderItemAmountMutation';

const schema = yup.object().shape({
    amount: yup.string().required(Translate.required)
});


const PreviewOrderItemEditPrice = ({ item, orderId, cancelEdit }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const updateOrderItemAmountMutation = useUpdateOrderItemAmountMutation();

    const onSubmit = useCallback(async (values: any) => {
        if (!values || !item?.id) return;
        try {
            setLoading();
            const result = await updateOrderItemAmountMutation.mutateAsync({
                id: item.id,
                amount: Number(values?.amount?.replace(/\./gmi, '').replace(/,/gi, '.'))
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: [`get-order-${orderId}`]});
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                cancelEdit();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    }, [item, setLoading, resetLoading, updateOrderItemAmountMutation, cancelEdit]);

    const handleCancel = useCallback(() => {
        cancelEdit();
    }, [cancelEdit]);

    return (
        <Formik
            initialValues={{
                amount: Number(item.amount)
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            <PreviewOrderItemEditPriceForm>
                <PreviewOrderItemEditPriceContainer>
                    <FormikInputCurrency
                        variant={'outlined'}
                        label={'amount'}
                        name={'amount'}
                        fullWidth={false}
                    />
                    <PreviewOrderItemEditPriceButtons>
                        <ConfirmButton type="submit"><Check /></ConfirmButton>
                        <CancelButton onClick={handleCancel}><Clear /></CancelButton>
                    </PreviewOrderItemEditPriceButtons>
                </PreviewOrderItemEditPriceContainer>
            </PreviewOrderItemEditPriceForm>
        </Formik>
    );
};

export default PreviewOrderItemEditPrice;