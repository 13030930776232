import {
  random,
  noop
} from 'lodash';
import { Translate } from '../internationalization/translate';
import moment from 'moment-timezone';

export const NOOP = noop;

export const emptyObject = {} as any;

export const emptyArray = [] as any[];

export const guid = () => `${random(10000,100000)}-${random(10000,100000)}-${random(10000,100000)}`;

export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));


export const formatDateTime = (date: Date): string => {
  const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const formattedDate: string = `${date.getDate()}.${months[date.getMonth()]}.${date.getFullYear()}`;
  const formattedTime: string = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  return `${formattedDate} ${formattedTime}`;
};

export const formatTime = (date?: string|number|Date, _format = 'HH:mm') => {
  if(!date) return '';
  return moment(date).tz('Europe/Belgrade').format(_format);
};


export const formatPrice = (value: string | number, decimal = 2) => {
  if (typeof value === 'undefined') return '0.00';
  const valNumber = typeof value === 'number' ? value : Number(value);
  const formatter = new Intl.NumberFormat('sr-RS', {
    minimumFractionDigits: decimal,
    style: 'currency',
    currency: 'RSD'
  });
  return formatter.format(valNumber);
};

export const formatNumber = (value: string | number, decimal = 2) => {
  if (typeof value === 'undefined') return '0.00';
  const valNumber = typeof value === 'number' ? value : Number(value);
  const formatter = new Intl.NumberFormat('sr-RS', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  return formatter.format(valNumber);
};

export const formatCurrency = (value: string, needReplace = true) => {
  let _value = value;
  if(needReplace) _value = _value.replace(/\./g, '').replace(/,/g, '.');
  const number = parseFloat(_value);
  if (isNaN(number)) {
    return '';
  }
  const dd = number.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return dd;
};



export const formatPriceSymbol = (value: string | number) => !value ? `${formatPrice(value)}` : formatPrice(value);


export const vehicleOwnership = (ownership: number): string => {
  return ownership == 1 ? Translate.yes : Translate.no;
};

export const vehicleType = (type: number): string => {
  return type == 1 ? Translate.vehicleTypeCar : type == 2 ? Translate.vehicleTypeMotor : Translate.vehicleTypeBicycle;
};

export const getFileBase64 = (ff: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event: any) {
      const base64String = event.target.result;
      resolve(base64String);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(ff);
  });
};


export const getDatesBetweenDates = (from: string|number|Date, to: string|number|Date, format = 'YYYY-MM-DD') => {
  const startDate = moment(from);
  const endDate = moment(to);
  const dates = [];
  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.clone().format(format));
    startDate.add(1, 'days');
  }
  return dates.map(x=> ({
    date: x
  }));
};

