import React, { useCallback, useContext, useEffect } from 'react';
import {
    NotificationListContent,
    NotificationListHeader,
    NotificationListHeaderCloseButton,
    NotificationListHeaderTitle,
    NotificationListHeaderTitleContainer,
    NotificationsListContainer
} from './style';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import NotificationListItem from './NotificationListItem';
import useGetNotifications from '../../../../core/api/hooks/query/notifications/useGetNotifications';
import UiDataContext from '../../../../context/UiContext/context';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useModal } from '../../../../hooks/useModal';
import ClearNotificationsModal from './modal/ClearNotificationsModal';

const NotificationsList = ({onClose, setRefetchCount}: any) => {

    const {t} = useTranslation();
    const { data, isFetched, refetch } = useGetNotifications();
    const {messages} = useContext(UiDataContext);

    const { openModal: openDialogClearNotification, closeModal: closeDialogClearNotification } = useModal({
        modal: ClearNotificationsModal,
        isActionsButtonsVisible: true,
        isCloseAction: true,
        noPadding: false
    });



    const setRefetch = useCallback(()=> {
        refetch();
        setRefetchCount();
    },[refetch, setRefetchCount]);


    const clearAll = useCallback(()=> {
        openDialogClearNotification({
           closeModal: closeDialogClearNotification,
           setRefetch
        });
    },[openDialogClearNotification, closeDialogClearNotification, setRefetch]);


    useEffect(() => {
        if(!messages || !messages?.length) return;
        refetch();
    }, [messages]);



    return (
        <NotificationsListContainer>
            <NotificationListHeader>
                <NotificationListHeaderTitleContainer>
                    <NotificationListHeaderCloseButton onClick={clearAll} title={'Obriši sve'}>
                        <ClearAllIcon />
                    </NotificationListHeaderCloseButton>
                    <NotificationListHeaderTitle>{`${t('notificationsLabel')} (${data?.length || 0})`}</NotificationListHeaderTitle>
                </NotificationListHeaderTitleContainer>
                <NotificationListHeaderCloseButton onClick={onClose}>
                    <CloseOutlinedIcon />
                </NotificationListHeaderCloseButton>
            </NotificationListHeader>
            <NotificationListContent>
               {isFetched && !data.length ?  <>Nema obaveštenja</> :(data || []).map((item: any, index: number) => <NotificationListItem key={index} item={item} setRefetch={setRefetch} />)}
            </NotificationListContent>
        </NotificationsListContainer>
    );
};

export default NotificationsList;