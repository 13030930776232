import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';
import store from '../../../../store';
import { setDrivers } from '../../../../store/app/appSlice';


const useGetDriversQuery = (key = 'get-drivers') => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: [key],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/driver',
        query: {
          active: true,
          perPage: 1000
        },
        version: 'v1'
      }));
      if(res?.data?.data && key === 'get-drivers-all') {
        store.dispatch(setDrivers(res?.data?.data?.rows));
      }
      return res.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false
  });
  return { data, isLoading, isFetched, isFetching };
};

export default useGetDriversQuery;
