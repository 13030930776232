import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useOrderAddItemMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({id, description, address, amount, pickUpValue, addressJson, isPurchase}: {id: number, description: string, pickUpValue: number|string, address: string, addressJson?: any, amount: number, isPurchase?: number}) => {
            const response = await axiosClient.post(createUrl({
                url: `/dispatcher/order-free/item/${id}`,
                version: 'v1',
            }), {
                description,
                address,
                amount,
                addressJson,
                pickUpValue,
                isPurchase
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useOrderAddItemMutation;
