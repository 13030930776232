import useClearNotificationsMutation
    from '../../../../../../core/api/hooks/mutations/notifications/useClearNotificationsMutation';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { errorToast, successToast } from '../../../../../Utils/Toast';
import useModalFormData from '../../../../../../hooks/useModalFormData';
import UiDataContext from '../../../../../../context/UiContext/context';
import { ClearNotificationDialogContainer, ClearNotificationDialogSubText, ClearNotificationDialogText } from './style';
import Translation from '../../../../../Translation';


const ClearNotificationsModal = ({closeModal, setRefetch}: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const clearNotificationsMutation = useClearNotificationsMutation();


    const handlerOnSubmit = useCallback(async () => {
        try {
            setLoading();
            const result = await clearNotificationsMutation.mutateAsync();
            if (result?.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [setLoading, clearNotificationsMutation, t, closeModal, setRefetch, resetLoading]);

    useModalFormData({
        modalTitle: t('notificationsClearTitle'),
        handlerOnSubmit
    });

    return (
        <ClearNotificationDialogContainer>
            <Translation use={'notificationsClearText'} as={ClearNotificationDialogText} />
            <Translation use={'notificationsClearSubText'} as={ClearNotificationDialogSubText} />
        </ClearNotificationDialogContainer>
    );
};

export default ClearNotificationsModal;