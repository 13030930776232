import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import { MapContainer } from 'react-leaflet';
import { Colors } from '../../../../../../theme/palette';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';

export const ChangeOrderZoneMapPart = styled(Flex)`
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 0 10px;
`;

export const ChangeOrderZoneMapView = styled(MapContainer)`
    &&& {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const ChangeOrderZoneButtonsPart = styled(Flex)`
    gap: 25px;
    justify-content: flex-end;
    align-items: center;
`;


export const ChangeOrderZoneButton = styled(ButtonIcon)`
    &&& {
        background: ${Colors.white};      
    }
`;
