import React, {useMemo} from 'react';
import { PreviewOrderBidContainer } from '../style';
import { ORDER_BID_STATUS } from '../../../../d';

const PreviewOrderBid = ({ bid }: any) => {


    const [color] = useMemo(()=> ([
        bid.status === ORDER_BID_STATUS.WON ? 'green' : 'white'
    ]),[bid]);

    const formatter = (value: any) => {
        return value ? `${value} min` : '';
    };

    return <PreviewOrderBidContainer label={`${bid.driver?.firstName}`} value={bid?.bidTime || ''} format={formatter} valueColor={color} labelColor={color} align={'flex-end'} />;
};

export default PreviewOrderBid;