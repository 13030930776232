import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import {
    ChangeOrderZoneCenterPositionButton,
    ChangeOrderZoneCenterPositionContainer, ChangeOrderZoneCenterPositionKeyboard,
    ChangeOrderZoneCenterPositionMapView, ChangeOrderZoneCenterPositionRow,
    ChangeOrderZoneCenterPositionTitle
} from './style';
import { Circle, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import UiDataContext from '../../../../../context/UiContext/context';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Colors } from '../../../../../theme/palette';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import useUpdateMultiSettingsMutation
    from '../../../../../core/api/hooks/mutations/settings/useUpdateMultiSettingsMutation';
import { SETTINGS_KEYS } from '../../../../../store/app/d';

const stepInMeters = 50;

const ChangeOrderZoneCenterPosition = ({ closeModal }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const dispatch = useAppDispatch();
    const settings = useSelector(selectSettings);
    const [zone] = useMemo(() => ([
        settings?.['ORDER_ZONE_1']?.valueJson
    ]), [settings]);
    const [center, setCenter] = useState<any>(zone?.center);
    const updateMultiSettingsMutation = useUpdateMultiSettingsMutation();

    const metersToDegrees = (meters: any, isLatitude = true) => {
        if (isLatitude) {
            return meters / 111000;
        } else {
            return meters / (111000 * Math.cos(center.lat * (Math.PI / 180)));
        }
    };

    const changeCenter = useCallback(
        (direction = 'n') => {
            setCenter((prev: any) => {
                const newCenter = { ...prev };
                const latStep = metersToDegrees(stepInMeters, true); // Latitude step in degrees
                const lngStep = metersToDegrees(stepInMeters, false); // Longitude step in degrees

                switch (direction) {
                    case 'n':
                        newCenter.lat += latStep;
                        break;
                    case 's':
                        newCenter.lat -= latStep;
                        break;
                    case 'e':
                        newCenter.lng += lngStep;
                        break;
                    case 'w':
                        newCenter.lng -= lngStep;
                        break;
                    default:
                        break;
                }
                return newCenter;
            });
        },
        [setCenter, center]
    );


    useEffect(() => {
        const handleKeyDown = (event: any) => {
            const { key } = event;
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key)) {
                switch (key) {
                    case 'ArrowUp':
                        changeCenter('n');
                        break;
                    case 'ArrowDown':
                        changeCenter('s');
                        break;
                    case 'ArrowLeft':
                        changeCenter('w');
                        break;
                    case 'ArrowRight':
                        changeCenter('e');
                        break;
                }
                changeCenter(key);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [changeCenter]);

    const onSubmit = useCallback(async () => {
        if (!center) return;
        try {
            setLoading();
            const result = await updateMultiSettingsMutation.mutateAsync({
                keys: [SETTINGS_KEYS.ORDER_ZONE_1, SETTINGS_KEYS.ORDER_ZONE_2, SETTINGS_KEYS.ORDER_ZONE_3],
                valueJson: {
                    center
                }
            });
            if (result?.status === 200) {
                if (result?.data) {
                    dispatch(setSettings({ ...(result?.data || {}) }));
                }
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        } finally {
            resetLoading();
        }
    }, [resetLoading, setLoading, closeModal, dispatch, center, updateMultiSettingsMutation]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeOrderZoneCenterPositionContainer>
            <Translation use={Translate.settingsOrderCenterPositionChangeTitle}
                         as={ChangeOrderZoneCenterPositionTitle} />
            <ChangeOrderZoneCenterPositionMapView
                center={zone?.center || center}
                zoom={11.7}
                scrollWheelZoom={true}
                dragging
            >
                <TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
                {zone ? <Circle
                    key={zone?.id}
                    center={center}
                    radius={zone?.radius || 10000}
                    pathOptions={{
                        color: zone?.color,
                        fillColor: zone?.color,
                        fillOpacity: 0.2
                    }}
                /> : null}
                <ChangeOrderZoneCenterPositionKeyboard>
                    <ChangeOrderZoneCenterPositionButton
                        title={t(Translate.up)}
                        onClick={() => changeCenter('n')}
                    >
                        <NorthIcon />
                    </ChangeOrderZoneCenterPositionButton>
                    <ChangeOrderZoneCenterPositionRow>
                        <ChangeOrderZoneCenterPositionButton
                            title={t(Translate.right)}
                            onClick={() => changeCenter('w')}
                        >
                            <WestIcon />
                        </ChangeOrderZoneCenterPositionButton>
                        <ChangeOrderZoneCenterPositionButton
                            title={t(Translate.down)}
                            onClick={() => changeCenter('s')}
                        >
                            <SouthIcon />
                        </ChangeOrderZoneCenterPositionButton>
                        <ChangeOrderZoneCenterPositionButton
                            title={t(Translate.left)}
                            onClick={() => changeCenter('e')}
                        >
                            <EastIcon />
                        </ChangeOrderZoneCenterPositionButton>
                    </ChangeOrderZoneCenterPositionRow>
                </ChangeOrderZoneCenterPositionKeyboard>
            </ChangeOrderZoneCenterPositionMapView>
            <SettingsDialogButtonsForm>
                <SettingsDialogButton onClick={onSubmit}>
                    <Translation use={Translate.save} />
                </SettingsDialogButton>
                <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                    <Translation use={Translate.cancel} />
                </SettingsDialogButton>
            </SettingsDialogButtonsForm>
        </ChangeOrderZoneCenterPositionContainer>
    );
};

export default ChangeOrderZoneCenterPosition;