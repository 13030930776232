import styled from 'styled-components';
import { Flex } from '../../assets/style';


export const DatePickerContainer = styled(Flex).withConfig({
    shouldForwardProp: (prop) =>
        !['InputProps'].includes(prop),
})<{InputProps?: any,}>`
    
   &&& {


       /*.MuiInputAdornment-root {
           color: white;
           background-color: #000000;
           min-height: 57px;
           min-width: 40px;
           display: flex;
           justify-content: center;
           margin: 0;

           &.MuiInputAdornment-positionEnd {
               border-top-right-radius: 20px;
               border-bottom-right-radius: 20px;
           }

       }
*/

       
       input {
           color: white;
           background-color: black;
           border-top-left-radius: 20px;
           border-bottom-left-radius: 20px;
           border-top-right-radius: ${props => props?.InputProps?.endAdornment ? 0 : 20}px;
           border-bottom-right-radius: ${props => props?.InputProps?.endAdornment ? 0 : 20}px;
       }
       
       .MuiOutlinedInput-root {
           font-size: 105%;
           min-height: 57px;
           border-radius: 10px;
           border-color: transparent;

           &.Mui-disabled {
               border-color: rgba(175, 175, 175, 0.15);
           }

           input {
               //font-size: 24px;
              // padding: 0 19px;
           }


           .MuiOutlinedInput-input {
               color: white;

               &::placeholder {
                   color: white;
               }


               &.Mui-disabled {
                   cursor: not-allowed;
               }
           }


           .MuiOutlinedInput-notchedOutline {
               border-color: white;
               color: white;
           }
      
           &.Mui-disabled {

               .MuiOutlinedInput-notchedOutline {
                   cursor: not-allowed;
                   border-color: rgba(175, 175, 175, 0.55);
               }
           }

           &.Mui-error {
               color: #d32f2f;
               border-color: transparent;

               .MuiOutlinedInput-input {
                   color: #d32f2f;
               }

               .MuiOutlinedInput-notchedOutline {
                   border-color: #d32f2f;
               }

               .MuiInputAdornment-root {
                   .MuiSvgIcon-root {
                       color: #d32f2f;
                   }
               }
           }
       }
       
   } 
    
`;