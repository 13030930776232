import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import { Flex } from 'assets/style';
import { Colors } from 'theme/palette';
import ButtonIcon from '../../../../../components/button/ButtonIcon';


export const SettingsOrderZoneMapContainer = styled(Flex)`
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-left: 1px solid ${Colors.black};
`;


export const SettingOrderZoneHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
`;
export const SettingsOrderZoneContent = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    flex: 2;
`;

export const SettingOrderZoneTitle =  styled(Flex)`
    font: normal normal 400 20px/26.5px "Readex Pro";
    color: ${Colors.black};
`;

export const SettingsOrderZoneMapPart = styled(Flex)`
    flex: 2;
    width: 100%;
    padding: 0 10px;
`;

export const SettingsOrderZoneMapView = styled(MapContainer)`
    &&& {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const SettingsOrderZoneCenter = styled(ButtonIcon)`
    &&& { 
        background: ${Colors.black};
        color: ${Colors.white};
        width: 32px;
        height: 32px;
        padding: 4px;
    }
`;
