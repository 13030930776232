import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';
import { Form } from 'formik';
import { SettingsDialogButtonsForm } from '../../../view/style';


export const ChangeOrderPurchaseStopPriceNoAddressContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    min-width: 600px;
    max-width: 600px;
`;


export const ChangeOrderPurchaseStopPriceNoAddressForm = styled(Form)`
  min-width: 550px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
    
    ${SettingsDialogButtonsForm}{
        max-width: 65%;
    }
`;

export const ChangeOrderPurchaseStopPriceNoAddressTitle = styled(Typography).attrs({
    variant: 'h6'
})`
    &&& {
        text-align: center;
        margin-bottom: 15px;
    }
`;