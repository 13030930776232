import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    SettingOrderZoneHeader,
    SettingOrderZoneTitle, SettingsOrderZoneCenter,
    SettingsOrderZoneContent,
    SettingsOrderZoneMapContainer,
    SettingsOrderZoneMapPart,
    SettingsOrderZoneMapView
} from './style';
import { Circle, Popup, Rectangle, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import {
    selectSettingsOrderZoneOne,
    selectSettingsOrderZoneThree,
    selectSettingsOrderZoneTwo
} from 'store/app/appSlice';
import { useSelector } from 'react-redux';
import SettingsOrderZoneMapPrice from '../SettingsOrderZoneMapPrice';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Translate } from '../../../../../internationalization/translate';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../../../hooks/useModal';
import ChangeOrderZoneCenterPosition from '../../Dialogs/ChangeOrderZoneCenterPosition';

const CreateCustomPane = ({ setRectangleBounds }: any) => {
    const map = useMap();
    map.createPane('circlePane1');
    map.createPane('circlePane2');
    (map.getPane('circlePane2') as any).style.zIndex = 400;
    (map.getPane('circlePane1') as any).style.zIndex = 500;


    useEffect(() => {
        if (map) {
            const bounds = map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            setRectangleBounds([[sw.lat, sw.lng], [ne.lat, ne.lng]]);
        }
    }, []);

    return null;
};


const SettingsOrderZoneMap = () => {
        const { t } = useTranslation();
        const zone1 = useSelector(selectSettingsOrderZoneOne);
        const zone2 = useSelector(selectSettingsOrderZoneTwo);
        const zone3 = useSelector(selectSettingsOrderZoneThree);
        const [circleRadii, setCircleRadii] = useState([] as any);
        const [rectangleBounds, setRectangleBounds] = useState<any>(null);

        const mapRef = useRef() as any;

        const zones = useMemo(() => ([
            zone1,
            zone2
        ].filter(x => !!x)), [zone1, zone2, zone3]);

        useEffect(() => {
            if (!zones?.length) return;
            setCircleRadii(zones);
        }, [zones]);

        const updateMapRadius = (_mapRef?: any) => {
            const map = _mapRef?.current as any || _mapRef?.sourceTarget || mapRef?.current;
            if (!map) return;
            const bounds = map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            setRectangleBounds([[sw.lat, sw.lng], [ne.lat, ne.lng]]);
        };

        const MapEventsHandler = () => {
            useMapEvents({
                moveend: updateMapRadius,
                zoomstart: updateMapRadius,
                movestart: updateMapRadius,
                zoomend: updateMapRadius
            });
            return null;
        };

        const { openModal: openModalChangeOrderZoneCenter, closeModal: closeModalChangeOrderZoneCenter } = useModal({
            modal: ChangeOrderZoneCenterPosition,
            isActionsButtonsVisible: false,
            isCloseAction: false,
            noPadding: true
        });

        const handlerChangeCenter = useCallback(() => {
            openModalChangeOrderZoneCenter({
                closeModal: closeModalChangeOrderZoneCenter
            });
        }, [openModalChangeOrderZoneCenter, closeModalChangeOrderZoneCenter]);


        useEffect(() => {
            updateMapRadius(mapRef);
        }, []);

        return (
            <SettingsOrderZoneMapContainer>
                <SettingOrderZoneHeader>
                    <SettingOrderZoneTitle>Zone</SettingOrderZoneTitle>
                    <SettingsOrderZoneCenter title={t(Translate.settingsOrderZoneChangeCenter)}
                                             onClick={handlerChangeCenter}>
                        <MyLocationIcon />
                    </SettingsOrderZoneCenter>
                </SettingOrderZoneHeader>
                <SettingsOrderZoneContent>
                    <SettingsOrderZoneMapPart>
                        <SettingsOrderZoneMapView
                            ref={mapRef}
                            center={{ lat: 43.58226, lng: 21.32918 }}
                            zoom={10}
                            scrollWheelZoom={true}
                            dragging
                            whenReady={() => {
                                updateMapRadius(mapRef);
                            }}
                        >
                            <TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
                            {circleRadii?.length ? circleRadii?.map((circle: any) => (
                                <Circle
                                    key={circle.id}
                                    center={circle.center}
                                    radius={circle.radius}
                                    pathOptions={{
                                        color: circle.color,
                                        fillColor: circle.color,
                                        fillOpacity: 0.2
                                    }}
                                    pane={`circlePane${circle?.id}`}
                                >
                                    <Popup>{circle?.popup}</Popup>
                                </Circle>
                            )) : null}
                            {rectangleBounds && (
                                <Rectangle
                                    bounds={rectangleBounds}
                                    pathOptions={{
                                        color: zone3?.color,
                                        fillOpacity: 0.2
                                    }}
                                >
                                    <Popup>{zone3?.popup}</Popup>
                                </Rectangle>
                            )}
                            <MapEventsHandler />
                            <CreateCustomPane setRectangleBounds={setRectangleBounds} />
                        </SettingsOrderZoneMapView>
                    </SettingsOrderZoneMapPart>
                    <SettingsOrderZoneMapPrice />
                </SettingsOrderZoneContent>
            </SettingsOrderZoneMapContainer>
        );
    }
;

export default SettingsOrderZoneMap;