import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';
import { Form } from 'formik';
import FormikInputTimeMask from '../../../../../components/Formik/FormikInputTimeMask';


export const ChangeOrderScheduledPendingTimeContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    min-width: 500px;
    max-width: 500px;
`;

export const ChangeOrderScheduledPendingTimeForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
`;

export const ChangeOrderScheduledPendingTimeTitle = styled(Typography).attrs({
    variant: 'h4'
})`
    text-align: center;
`;

export const ChangeOrderScheduledInput = styled(FormikInputTimeMask)`
    &&& {
        min-width: 300px;
        max-width: 300px;
    }
`;
