import styled, { css } from 'styled-components';
import { Autocomplete, Stack, Switch } from '@mui/material';
import { Flex } from '../../assets/style';


export const SearchAddressAutoComplete = styled(Autocomplete).withConfig({
    shouldForwardProp: (prop) =>
        !['value', 'disableClearable'].includes(prop),
})<{value?: any, disableClearable?: boolean}>`
    &&& {
        width: 100%;

        .MuiInputLabel-root {
            top: 0;
            
            transform: translate(14px, 14px) scale(1);
            
            ${props => props.value && css`
                transform: translate(16px, -9px) scale(0.75);
            `}
           
            &.Mui-focused {
                top:0 ;
                transform: translate(16px, -10px) scale(0.75);
            }
        }

        .MuiOutlinedInput-root  {
            input {
                padding: 0 35px 0 19px;
            }
        }
    }
`;


export const SearchAddressAutoCompleteShowChosen = styled(Flex)`
    flex-direction: column;
    width: 100%;
`;

export const SearchAddressAutoCompleteContainer = styled(Flex).withConfig({
    shouldForwardProp: (prop) =>
        !['fullWidth'].includes(prop),
})<{fullWidth?: number}>`
    position: relative;
    flex: 1;
    
    ${props => props.fullWidth && css`
        width: 100%;
    `}
`;

export const SearchAddressTypeContainer= styled(Stack)`
    &&& {
        position: absolute;
        top: -35px;
        right: 0;
    }
`;

export const SearchAddressType = styled(Switch)`
      &&& {
          
      }
`;