import styled from 'styled-components';
import { Flex } from 'assets/style';
import { MapContainer } from 'react-leaflet';
import { Typography } from '@mui/material';
import ButtonIcon from '../../../../../components/button/ButtonIcon';
import { Colors } from '../../../../../theme/palette';


export const ChangeOrderZoneCenterPositionContainer = styled(Flex)`
    flex-direction: column;
    width: 850px;
    height: 950px;
    gap: 10px;
    padding: 10px;
`;

export const ChangeOrderZoneCenterPositionTitle = styled(Typography).attrs({
    variant: 'h6'
})`
    &&& {
        text-align: center;
        margin-bottom: 15px;
    }
`;


export const ChangeOrderZoneCenterPositionMapView = styled(MapContainer)`
    &&& {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
    }
`;


export const ChangeOrderZoneCenterPositionRow = styled(Flex)`
    gap: 5px;
`;


export const ChangeOrderZoneCenterPositionKeyboard = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: absolute;
    left: 2px;
    bottom: 5px;
    z-index: 5000;
`;

export const ChangeOrderZoneCenterPositionButton = styled(ButtonIcon)`
    &&& {
        background: ${Colors.black};
        color: ${Colors.white};
        
        svg {
            width: 22px;
            height: 22px;
        }
    }
`;
