import styled, {css} from 'styled-components';
import {TextField} from '@mui/material';
import {grey} from '@mui/material/colors';


export const TextFieldContainer = styled(TextField).withConfig({
    shouldForwardProp: (prop) => !['isDark'].includes(prop),
})<{fullWidth?: boolean, value?: any, InputProps?: any, icon?:any, label?: string, iconRight?: any, isDark?: boolean}>`
  &&& {

    ${props => !props.isDark && css`
        input {
            color: black !important;
            background-color: white !important;
        }
        
        .MuiFormControl-root {
            background: white;
            color: white;
            border-color: #000000;
            
            &.MuiFocused {
                .MuiOutlinedInput-notchedOutline {
                    border-color: black;
                }
            }
        }
        
        .MuiInputAdornment-root {
            color: #000000 !important;
            background-color: white !important;
        }


        .MuiOutlinedInput-notchedOutline {
            border-color: black !important;
        }
        
        .MuiFormLabel-root {
            background: white !important;
            color: black !important;
            &.Mui-error {
                color: #d32f2f !important;
            }
        }

        .MuiOutlinedInput-root {
            background: white;
            color: white;
            border-color: #000000;

            &.Mui-error {
                color: #d32f2f !important;
                border-color: transparent;

                .MuiOutlinedInput-input {
                    color: #d32f2f !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #d32f2f !important;
                }

                .MuiInputAdornment-root {
                    .MuiSvgIcon-root {
                        color: #d32f2f !important;
                    }
                }
            }

        }

     
        
    `} 
      
      
    ${props => props.fullWidth && css`
      width: 100%;
    `}

    ${props => props.InputProps?.readOnly && css`
      color: ${grey['300']};

      input {
        cursor: not-allowed;
      }
    `}
    .MuiInputLabel-root {
      color: white;
      top: 8px;

      ${props => !!props?.icon && css`
        transform: translate(16px, 18px);
      `}
      ${props => !!props?.value?.length && css`
        transform: translate(16px, -18px) scale(0.75);
      `}
      
      &.Mui-focused {
        transform: translate(16px, -18px) scale(0.75);
      }

      &.Mui-disabled {
        cursor: not-allowed;
        color: rgba(175, 175, 175, 0.65);
      }


      &.Mui-error {
        color: #d32f2f;
      }
    }

    .MuiInputAdornment-root {
      color: white;
      background-color: #000000;
      min-height: 57px;
      min-width: 40px;
      display: flex;
      justify-content: center;
      margin: 0;

      &.MuiInputAdornment-positionEnd {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
          
    }

    input {
      color: white;
      background-color: black;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: ${props => props?.InputProps.endAdornment ? 0 : 20}px;
      border-bottom-right-radius: ${props => props?.InputProps.endAdornment ? 0 : 20}px;
    }


    .MuiOutlinedInput-root {
      font-size: 105%;
      min-height: 57px;
      border-radius: 20px;
      border-color: transparent;
      padding-right: 0;
        
      &.Mui-disabled {
        border-color: rgba(175, 175, 175, 0.15);
      }

      input {
        //font-size: 24px;
        padding: 0 19px;
      }

      input::placeholder {
        color: white;
        opacity: 1;
      }


      .MuiOutlinedInput-input {
        height: 100%;
        color: white;

        &::placeholder {
          color: white;
        }


        &.Mui-disabled {
          cursor: not-allowed;
        }


      }

      ${props => props.InputProps?.readOnly && css`
        border-color: ${grey['300']};
      `}
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        background: transparent;
        -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
      }


      &.Mui-disabled {

        .MuiOutlinedInput-notchedOutline {
          cursor: not-allowed;
          border-color: rgba(175, 175, 175, 0.55);
        }
      }

      &.Mui-error {
        color: #d32f2f;
        border-color: transparent;

        .MuiOutlinedInput-input {
          color: #d32f2f;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #d32f2f;
        }

        .MuiInputAdornment-root {
          .MuiSvgIcon-root {
            color: #d32f2f;
          }
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: white;
      color: white;

      ${props => props.label && css`
        legend {
          span {
            padding: 0 6px;
          }
        }
      `}


    }

    .MuiFormHelperText-root {
      min-height: 20px;
    }
  }
`;
