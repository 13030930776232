import React, { useCallback, useContext, useMemo } from 'react';
import {
    NotificationListActionButton,
    NotificationListContentIcon,
    NotificationListItemActions,
    NotificationListItemContainer,
    NotificationListItemContent,
    NotificationListItemContentRow,
    NotificationListItemContentSubText,
    NotificationListItemContentTitle,
    NotificationListItemRightPart,
    NotificationListRequestTime
} from './style';
import Translation from '../../../../Translation';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { Translate } from '../../../../../internationalization/translate';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../Utils/Toast';
import { NotificationType } from '../d';
import moment from 'moment';
import useRemoveNotificationMutation
    from '../../../../../core/api/hooks/mutations/notifications/useRemoveNotificationMutation';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SellIcon from '@mui/icons-material/Sell';

const NotificationListItem = ({ item, setRefetch }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const removeNotificationMutation = useRemoveNotificationMutation();

    const [title, text, id, createdAt, Icon, iconTitle] = useMemo(() => [
        (() => {
            switch (item.notificationType) {
                case NotificationType.ORDER_ITEM_FINISHED:
                case NotificationType.ORDER_FINISHED:
                case NotificationType.ORDER_ABORTED:
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH:
                case NotificationType.DRIVER_ORDER_BID_FINISHED:
                case NotificationType.DRIVER_MADE_ORDER_BID:
                    return Translate.notifications;
            }
        })(),
        (() => {
            switch (item.notificationType) {
                case NotificationType.ORDER_ITEM_FINISHED:
                    return t(Translate.notificationOrderItemFinishedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_FINISHED:
                    return t(Translate.notificationOrderFinishedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_ABORTED:
                    return t(Translate.notificationOrderAbortedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                    return t(Translate.notificationOrderCustomerNotShowText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH:
                    return t(Translate.notificationOrderCustomerNotShowFinishText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.DRIVER_ORDER_BID_FINISHED:
                    return t(Translate.notificationOrderBiddingFinishedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}`, bidTime: `${item?.value?.bidTime}` });
                case NotificationType.DRIVER_MADE_ORDER_BID:
                    return t(Translate.notificationDriverOrderBidMadeText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
            }
        })(),
        item.id,
        item.value?.time ? moment(new Date(item.value?.time)).tz('Europe/Belgrade').format('HH:mm') : '',
        (()=> {
           switch(item.notificationType) {
               case NotificationType.ORDER_ITEM_FINISHED:
                   return TaskAltIcon;
               case NotificationType.ORDER_FINISHED:
                   return AssignmentTurnedInIcon;
               case NotificationType.ORDER_ABORTED:
                   return NotInterestedIcon;
               case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
               case NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH:
                   return PersonOffIcon;
               case NotificationType.DRIVER_MADE_ORDER_BID:
                   return RequestQuoteIcon;
               case NotificationType.DRIVER_ORDER_BID_FINISHED:
                   return SellIcon;
           }
        })(),
        (()=> {
            switch(item.notificationType) {
                case NotificationType.ORDER_ITEM_FINISHED:
                    return Translate.notificationOrderItemFinishedTitle;
                case NotificationType.ORDER_FINISHED:
                    return Translate.notificationOrderFinishedTitle;
                case NotificationType.ORDER_ABORTED:
                    return Translate.notificationOrderAbortedTitle;
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH:
                    return Translate.notificationOrderCustomerNotShowTitle;
                case NotificationType.DRIVER_MADE_ORDER_BID:
                case NotificationType.DRIVER_ORDER_BID_FINISHED:
                    return Translate.notificationsBid;
                default:
                    return '';
            }
        })()
    ], [item, t]);


    const removeNotificationRequest = useCallback(async ()=> {
        if(!id) return; if(!id) return;
        try {
            setLoading();
            const result = await removeNotificationMutation.mutateAsync(id);
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    },[id, resetLoading, setLoading, setRefetch, t, removeNotificationMutation]);
    
    return (
        <NotificationListItemContainer>
            <NotificationListItemContentRow>
                <NotificationListContentIcon title={t(iconTitle)}>
                    {Icon ? <Icon />: <>&nbsp;</>}
                </NotificationListContentIcon>
                <NotificationListItemContent>
                    <Translation use={title} as={NotificationListItemContentTitle} />
                    <NotificationListItemContentSubText>{text}</NotificationListItemContentSubText>
                </NotificationListItemContent>
            </NotificationListItemContentRow>

            <NotificationListItemRightPart>
                <NotificationListRequestTime>{createdAt}</NotificationListRequestTime>
                <NotificationListItemActions>
                    <NotificationListActionButton  title={'Obriši'} onClick={removeNotificationRequest}>
                        <CancelOutlinedIcon />
                    </NotificationListActionButton>
                </NotificationListItemActions>
            </NotificationListItemRightPart>

        </NotificationListItemContainer>
    );
};

export default NotificationListItem;