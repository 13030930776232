import styled, { css } from 'styled-components';
import Input from 'components/input/Input';


export const TableSearchInputContainer = styled(Input).withConfig({
    shouldForwardProp: (prop) =>
        !['InputProps'].includes(prop),
})<{InputProps?: any}>`
    &&& {
        .MuiOutlinedInput-root {
            min-height: 40px;
            border-radius: 10px;
            border-color: #666666;
            font: normal normal 400 18px/24px 'Readex Pro';


            &.Mui-focused {
                outline: none;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #666666;
            }

            .MuiInputAdornment-root {
                background-color: transparent;
                margin: 0;
            }


            input {
                background-color: transparent;
                padding: 10px 17px 10px 0;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: ${props => props?.InputProps?.endAdornment ? 0 : 10}px;
                border-bottom-right-radius: ${props => props?.InputProps?.endAdornment ? 0 : 10}px;
            }

            input::placeholder {
                color: #666666;
                opacity: 1;
            }

            .MuiInputAdornment-root {
                color: #666666;
            }

            .MuiOutlinedInput-input {
                height: 100%;
                color: #666666;

                &::placeholder {
                    color: #666666;
                }


                &.Mui-disabled {
                    cursor: not-allowed;
                }

            }

            .MuiInputAdornment-positionEnd {
                color: #eaeaea;
                cursor: not-allowed;

                ${props => props.value?.length && props.isClearable && css`
                    color: #666666;
                    cursor: pointer;
                `}
            }

        }
    }
`;
