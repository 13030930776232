import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';
import { Colors } from '../../../../../../../theme/palette';
import { Form } from 'formik';

export const PreviewOrderItemEditPriceForm = styled(Form)`
    
`;


export const PreviewOrderItemEditPriceContainer = styled(Flex)`
    gap: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`;

export const PreviewOrderItemEditPriceButtons = styled(Flex)`
    gap: 15px;
    align-items: center;
    flex-direction: row;
    padding-top: 10px;
`;

export const ConfirmButton = styled(ButtonIcon)`
    &&& {
        color: ${Colors.white};
        background: ${Colors.green70};
    }
`;

export const CancelButton = styled(ButtonIcon)`
    &&& {
        color: ${Colors.white};
        background: ${Colors.red};
    }
`;
