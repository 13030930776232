import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';


export const PurchaseStopPriceRowContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

export const PurchaseStopPriceRowRangeContainer = styled(Flex)`
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
`;