import styled, { css } from 'styled-components';
import { Flex } from '../../../../assets/style';
import { Colors } from '../../../../theme/palette';
import ButtonIcon from '../../../../components/button/ButtonIcon';


export const SettingsOrdersRowContainer = styled(Flex)<{noBorder?: false, isChild?: number}>`
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: ${props => !props?.isChild ? 'center' : 'flex-start'};
    padding: 10px 30px 10px;
    border-bottom: ${props => props?.noBorder ? 'none' : `1px solid ${Colors.black}`};
`;

export const SettingsShiftRowPreviewContainer = styled(Flex)`
    flex-direction: column;
    flex: 2;
`;

export const SettingsOrdersRowActionContainer = styled(Flex)<{isButtonAbsolute?: boolean}>`
    ${props => props.isButtonAbsolute && css`
        position: absolute;
        right: 5px;
        top: 5px;
        `}
`;

export const SettingsOrdersPreviewLabel = styled(Flex)`
    font: normal normal 400 20px/26.5px "Readex Pro";
    margin-bottom: 10px;
    color: ${Colors.black};
`;

export const SettingsOrdersPreviewValue = styled(Flex)`
    font: normal normal 400 16px/22.5px "Readex Pro";
    color: ${Colors.grey};
`;

export const SettingsOrdersButtonIconAction = styled(ButtonIcon)`
    &&& {
        background: ${Colors.black};
        color: ${Colors.white};
        width: 32px;
        height: 32px;
        padding: 4px;
        svg {
            width: 22px;
            height: 22px;
        }
    }
`;