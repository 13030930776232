import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useOrderCalculationMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/order-calculation',
                version: 'v1',
            }), data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useOrderCalculationMutation;
