import React from 'react';
import { SettingCalculationContainer } from './style';
import SettingsOrderZoneMap from '../../components/Orders/SettingsOrderZoneMap';
import SettingsOrderPrices from '../../components/Orders/SettingsOrderPrices';

const Calculation = () => {
    return (
        <SettingCalculationContainer>
            <SettingsOrderPrices />
            <SettingsOrderZoneMap />
        </SettingCalculationContainer>
    );

};

export default Calculation;