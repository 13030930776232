import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';
import { Form } from 'formik';


export const ChangeOrderBiddingTimeContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    min-width: 400px;
    max-width: 400px;
`;

export const ChangeOrderBiddingTimeForm = styled(Form)`
  min-width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
`;

export const ChangeOrderBiddingTimeTitle = styled(Typography).attrs({
    variant: 'h4'
})`
    
`;
