import styled, { css } from 'styled-components';
import { Box, Paper, TableHead, TablePagination, TableRow, TextField, TableCell } from '@mui/material';
import { THCellContainer } from 'components/Table/TableHeader/style';
import { Flex } from 'assets/style';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Colors } from 'theme/palette';

export const TableBox = styled(Paper)`
  &&& {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: white;
    display: flex;
    flex-direction: column;
    color: black;
    padding: 30px 0 11px;
  }
`;
export const TableSearchInput = styled(TextField)`
  &&& {
    color: #666666;
    max-width: 380px;
  }
`;
export const TableHeaderContainer = styled(Box)`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }
`;

export const TableContentContainer = styled(Box)`
  flex: 2;
  
`;
export const StyleTableHeader = styled(TableHead)`
  th {
    color: black;
    border-bottom: 2px solid ${Colors.lightGrey};
    text-align: center;
  }
`;

export const StyledTableRow = styled(TableRow)`
  border-bottom: 2px solid ${Colors.lightGrey};
  
  td, th {
    color: black;
    padding: 10px 0;
    &:first-child {
      padding-left: 30px;
    }
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  :hover {
    background-color: ${Colors.lightGrey};
  }
`;

export const TablePaginationContainer = styled(TablePagination)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  
  &&& {
    color: black;
  }
`;




export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: relative;
`;

export const TableContainer = styled.div`
  display: block;
  flex: 2;
  width: 100%;
  min-height: calc(100% - 150px);
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  position: relative;
`;

export const THeadRow = styled(TableRow)`
  &.MuiTableRow-root {
   // color: #fff;
  }
  
  ${THCellContainer}{
    padding: 7px;
    border-top: 1px solid white;
    
    &:first-child {
      border-left: 1px solid white;
    }
  }

`;


export const TDCellContainer = styled(TableCell)`
  &&& {
    height: 30px;
    max-width: 100%;
    padding: 6px 12px;
    background: #FFFFFF;
    font: normal normal 400 16px/20px 'Readex Pro', sans-serif;
    color: black;
  }
`;
export const TBodyRow = styled(TableRow).withConfig({
    shouldForwardProp: (prop) =>
        !['selected', 'depth'].includes(prop),
})<{ selected?: boolean, depth?: number }>`
  &.MuiTableRow-root {
    position: relative;
    background: #FFFFFF;
    
    ${TDCellContainer} {
      border-bottom: 2px solid ${Colors.lightGrey};
    }
    
    ${props => props.selected && props.depth && css`
      background-color: transparent !important;
    `}
  
    
  }
`;



export const TDExpandedContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100%;
  font: normal normal 400 16px/20px 'Readex Pro', sans-serif;

  > div {
    &:last-child {
      padding-top: 2px;
    }
  }

`;


export const EmptyTableRows = styled(TableCell)`
  &&& {
    width: 100%;
    border-bottom: none;
    text-align: center;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    height: 500px;
  }
`;

export const EmptyTableContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const EmptyTableIconFilter = styled(SearchOffIcon)`
  &&& {
    height: 200px;
    width: 200px;
    color: #666666;
  }
`;

export const EmptyTableText = styled(Flex)`
    font: normal normal 40px/50px 'Readex Pro';
    color: #666666;
`;
