import React, { FC } from 'react';
import {
    SettingsOrderPriceNoAddPrice,
    SettingsOrderPriceNoAddRange,
    SettingsOrderStopPriceNoAddContainer
} from './style';

export type SettingsOrderPurchaseStopPriceNoAddProps = {
    id: number;
    range: string
    price: string,
    from: number,
    to?: number
}

const SettingsOrderPurchaseStopPriceNoAddress: FC<SettingsOrderPurchaseStopPriceNoAddProps> = ({
    range,
    price
}) => {

    return (
        <SettingsOrderStopPriceNoAddContainer>
            <SettingsOrderPriceNoAddRange>{range}</SettingsOrderPriceNoAddRange>
            <SettingsOrderPriceNoAddPrice>{price}</SettingsOrderPriceNoAddPrice>
        </SettingsOrderStopPriceNoAddContainer>
    );
};

export default SettingsOrderPurchaseStopPriceNoAddress;