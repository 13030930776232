import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { SettingsOrdersRowContainer } from '../style';
import { Colors } from '../../../../../theme/palette';

export const SettingsOrderZoneMapPriceContainer = styled(Flex)`
    flex-direction: row;
    flex: 1;
    height: 100%;
    min-height: 340px;
    padding: 5px 0;
    
    ${SettingsOrdersRowContainer}{
        padding: 10px;
    }
    
    > div {
        border-right: 1px solid ${Colors.black};
        border-top: 1px solid ${Colors.black};
        &:last-child {
            border-right: none;
        }    
    }
    
    

`;