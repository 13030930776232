import React, { useCallback, useContext, useMemo } from 'react';
import {
    ChangeOrderZoneContainer,
    ChangeOrderZoneForm,
    ChangeOrderZoneFormContent, ChangeOrderZoneFormRow,
    ChangeOrderZoneMapContainer,
    ChangeOrderZonePricesContainer,
    ChangeOrderZoneTitle
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import { Colors } from '../../../../../theme/palette';
import useUpdateSettingsMutation from '../../../../../core/api/hooks/mutations/settings/useUpdateSettingsMutation';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import ZonePurchaseStopPriceRow from './ZonePurchaseStopPriceRow';
import ChangeZoneMap from './ChangeZoneMap';
import FormikInputCurrency from '../../../../../components/Formik/FormikInputCurrency';

const validationSchema = yup.object({
    startPrice: yup.string().required('required'),
    startKmPrice: yup.string().required('required'),
    itemPrice: yup.string().required('required'),
    from_1: yup.string().required('required'),
    'to_1': yup.string().required('required'),
    'price_1': yup.string().required('required'),
    'from_2': yup.string().required('required'),
    'to_2': yup.string().required('required'),
    'price_2': yup.string().required('required'),
    'from_3': yup.string().required('required'),
    'to_3': yup.string().required('required'),
    'price_3': yup.string().required('required'),
    'from_4': yup.string().required('required'),
    'to_4': yup.string().required('required'),
    'price_4': yup.string().required('required'),
    'from_5': yup.string().required('required'),
    'to_5': yup.string().required('required'),
    'price_5': yup.string().required('required'),
    'from_6': yup.string().required('required'),
    'price_6': yup.string().required('required')
});

const ChangeOrderZone = ({ closeModal, zoneId }: any) => {
    const { t } = useTranslation();
    const settings = useSelector(selectSettings);
    const dispatch = useAppDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id, settingsKey, radius, startPrice, startKmPrice, itemPrice, isRedZone, oldData, purchase] = useMemo(() => {
        const key = `ORDER_ZONE_${zoneId}`;
        const sett = settings?.[`ORDER_ZONE_${zoneId}`];
        const data = sett.valueJson?.purchase;
        return [
            sett.id,
            key,
            sett?.valueJson?.radius,
            `${sett?.valueJson?.startPrice}`,
            `${sett?.valueJson?.startKmPrice}`,
            `${sett?.valueJson?.itemPrice}`,
            zoneId == '3',
            sett?.valueJson || {},
            Object.keys(data).reduce((acc: any, curr) => {
                const val = data[curr];
                const obj = Object.assign({
                    [`id_${curr}`]: curr,
                    [`from_${curr}`]: `${val?.from}`,
                    [`price_${curr}`]: `${val?.price}`
                }, curr != '6' ? {
                    [`to_${curr}`]: `${val?.to}`
                } : {});
                return {
                    ...acc,
                    ...obj
                };
            }, {} as any)
        ];
    }, [settings, zoneId]);

    const updateSettingsMutation = useUpdateSettingsMutation(id);


    const onSubmit = useCallback(async (values: any) => {
        if (!values || !settingsKey) return;
        try {
            setLoading();
            const valueJson = Array.from({ length: 6 }, (_, x) => x + 1).reduce((acc: any, x) => {
                return {
                    ...acc,
                    purchase: {
                        ...(acc?.purchase || {}),
                        [x]: {
                            from: Number(values[`from_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.')),
                            to: Number(values[`to_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.')),
                            price: Number(values[`price_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.'))
                        }
                    }
                };
            }, {
                startPrice: Number(values?.startPrice?.replace(/\./gmi, '').replace(/,/gi, '.')),
                startKmPrice: Number(values?.startKmPrice?.replace(/\./gmi, '').replace(/,/gi, '.')),
                itemPrice: Number(values?.itemPrice?.replace(/\./gmi, '').replace(/,/gi, '.')),
                radius: values?.radius
            });
            const result = await updateSettingsMutation.mutateAsync({
                key: settingsKey,
                valueJson: {
                    ...oldData,
                    ...valueJson
                }
            });
            if (result?.status === 200) {
                if (result?.data) {
                    dispatch(setSettings({
                        [settingsKey]: { ...(result?.data || {}) }
                    }));
                }
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        } finally {
            resetLoading();
        }
    }, [resetLoading, setLoading, updateSettingsMutation, closeModal, settingsKey, oldData]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeOrderZoneContainer isRedZone={isRedZone}>
            <Translation use={Translate.settingsOrderPurchaseStopPriceChangeTitle} as={ChangeOrderZoneTitle} />
            <Formik
                initialValues={{
                    ...purchase,
                    startPrice,
                    startKmPrice,
                    itemPrice,
                    radius
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <ChangeOrderZoneForm>
                    <ChangeOrderZoneFormContent>
                        <ChangeOrderZonePricesContainer>
                            <ChangeOrderZoneFormRow>
                                <FormikInputCurrency
                                    name={'startPrice'}
                                    variant={'outlined'}
                                    label={Translate.startPrice}
                                />
                                <FormikInputCurrency
                                    name={'startKmPrice'}
                                    variant={'outlined'}
                                    label={Translate.startKmPrice}
                                />
                                <FormikInputCurrency
                                    name={'itemPrice'}
                                    variant={'outlined'}
                                    label={Translate.itemPrice}
                                />
                            </ChangeOrderZoneFormRow>
                            <Translation use={Translate.purchaseRangePrice} as={ChangeOrderZoneTitle} />
                            {Array.from({ length: 6 }, (_, x) => (
                                <ZonePurchaseStopPriceRow key={x + 1} id={x + 1} />
                            ))}
                        </ChangeOrderZonePricesContainer>
                        {zoneId != '3' ? (<ChangeOrderZoneMapContainer>
                            <ChangeZoneMap zoneId={zoneId} />
                        </ChangeOrderZoneMapContainer>) : null}
                    </ChangeOrderZoneFormContent>
                    <SettingsDialogButtonsForm>
                        <SettingsDialogButton type={'submit'}>
                            <Translation use={Translate.save} />
                        </SettingsDialogButton>
                        <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                            <Translation use={Translate.cancel} />
                        </SettingsDialogButton>
                    </SettingsDialogButtonsForm>
                </ChangeOrderZoneForm>
            </Formik>

        </ChangeOrderZoneContainer>
    );
};

export default ChangeOrderZone;