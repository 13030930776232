import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useUpdateOrderItemMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({id, description, amount, orderId, pickUpValue}: {id: number, orderId?: number, description?: string, amount?: number, pickUpValue?: string|number}) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/order-free/item-update/${id}`,
                version: 'v1',
            }), { description, amount, orderId, pickUpValue }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useUpdateOrderItemMutation;
