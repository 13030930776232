import React, { useCallback, useMemo } from 'react';
import {
    ChangeOrderZoneButton,
    ChangeOrderZoneButtonsPart,
    ChangeOrderZoneMapPart,
    ChangeOrderZoneMapView
} from './style';
import { Circle, Popup, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../../../../../store/app/appSlice';
import { useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { Remove } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Translate } from '../../../../../../internationalization/translate';


const ChangeZoneMap = ({ zoneId }: any) => {
    
    const {t} = useTranslation();
    const { values, setFieldValue } = useFormikContext() as any;
    const settings = useSelector(selectSettings);
    const [zone, center] = useMemo(() => ([
        zoneId ? settings?.[`ORDER_ZONE_${zoneId}`]?.valueJson : {},
        zoneId != '3' ? settings?.[`ORDER_ZONE_${zoneId}`]?.valueJson?.center : settings?.['ORDER_ZONE_1']?.valueJson?.center
    ]), [settings, zoneId]);

    const changeRadius = useCallback((isInc = false) => {
        setFieldValue('radius', values.radius + (500 * (isInc ? 1 : -1)), false);
    }, [values, setFieldValue]);

    const handlerIncreaseRadius = useCallback(() => {
        changeRadius(true);
    }, [changeRadius]);

    const handlerDecreaseRadius = useCallback(() => {
        changeRadius();
    }, [values, setFieldValue]);

    return (
        <ChangeOrderZoneMapPart>
            <ChangeOrderZoneButtonsPart>
                <ChangeOrderZoneButton
                    title={t(Translate.increaseRadius)}
                    onClick={handlerIncreaseRadius}
                >
                    <AddIcon />
                </ChangeOrderZoneButton>
                <ChangeOrderZoneButton
                    title={t(Translate.decreaseRadius)}
                    onClick={handlerDecreaseRadius}
                >
                    <Remove />
                </ChangeOrderZoneButton>
            </ChangeOrderZoneButtonsPart>
            <ChangeOrderZoneMapView
                center={center}
                zoom={10}
                scrollWheelZoom={true}
                dragging
            >
                <TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
                {zone ? <Circle
                    key={zone?.id}
                    center={zone?.center}
                    radius={values?.radius || 10000}
                    pathOptions={{
                        color: zone?.color,
                        fillColor: zone?.color,
                        fillOpacity: 0.2
                    }}
                >
                    <Popup>{zone?.popup}</Popup>
                </Circle> : null}
            </ChangeOrderZoneMapView>
        </ChangeOrderZoneMapPart>
    );
};

export default ChangeZoneMap;