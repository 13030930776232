import { useField } from 'formik';
import Timepicker from '../Timepicker';


const FormikTimepicker = ({ name, ...rest }: any) => {
    const [field, meta, helper] = useField({
        name,
        ...rest
    });

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched,
        helperText: Boolean(meta.touched) && meta.error
    };
    const onChange = (value: any) => {
        helper.setValue(value, true);
    };

    return (
        <Timepicker
            {...newProps}
            slotProps={{
                textField: {
                    error: newProps.error
                }
            }}
            value={newProps.value ? new Date(newProps.value) : null}
            onChange={onChange}
        />
    );


};

export default FormikTimepicker;