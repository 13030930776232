import { AppBarProps } from 'components/appbar/style';
import {
    DrawerContainer,
    DrawerList,
    DrawerListItem,
    DrawerListItemButton,
    DrawerListItemIcon,
    DrawerListItemText,
    DrawerLogo,
    DrawerToolbar,
    DrawerVersionBox,
    DriverVersionApp
} from 'components/drawer/style';
import logo from 'assets/images/logo.png';
import { sidebarMenu } from 'components/drawer/d';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

const DrawerItem = ({ text, Icon, path, queryStr, disabled, childrenPaths }: any) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handlerClick = useCallback(() => {
        if (disabled) return;
        navigate(`${path}${queryStr ? queryStr : ''}`, { replace: false });
    }, [disabled, path, queryStr, navigate]);

    const selected = useMemo(() => !!(path === pathname) || !!(childrenPaths || [])?.includes(pathname), [path, pathname]);

    return (
        <DrawerListItem key={text} selected={selected} disablePadding disabled={disabled}>
            <DrawerListItemButton onClick={handlerClick}>
                <DrawerListItemIcon>
                    <Icon />
                </DrawerListItemIcon>
                <DrawerListItemText primary={text} />
            </DrawerListItemButton>
        </DrawerListItem>
    );
};

const Drawer = ({ open }: AppBarProps) => {

    const navigate = useNavigate();
    const version = process.env.REACT_APP_VERSION;

    const handlerGoHome = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <DrawerContainer
            open={open}
            variant="permanent"
            anchor="left"
        >
            <DrawerToolbar open={open}>
                <DrawerLogo open={open} src={logo} onClick={handlerGoHome} alt={'DingDong logo'} />
            </DrawerToolbar>
            <DrawerList open={open}>
                {sidebarMenu.map((menuItem, index) => <DrawerItem key={index} {...menuItem} />)}
            </DrawerList>
            <DrawerVersionBox>
                <DriverVersionApp>v {version}</DriverVersionApp>
            </DrawerVersionBox>
        </DrawerContainer>
    );
};

export default Drawer;
