import React, { useCallback, useContext } from 'react';
import useModalFormData from 'hooks/useModalFormData';
import { useTranslation } from 'react-i18next';
import { Translate } from 'internationalization/translate';
import {
    OrderAddItemButton,
    OrderAddItemContainer,
    OrderAddItemFooter,
    OrderAddItemForm
} from '../../views/OrdersViewPart/OrderViewList/style';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import AddressSearch from '../../../../../components/Autocomplete/AddressSearch';
import FormikInputCurrency from '../../../../../components/Formik/FormikInputCurrency';
import Translation from '../../../../../components/Translation';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import UiDataContext from '../../../../../context/UiContext/context';
import useOrderAddItemMutation from '../../../../../core/api/hooks/mutations/order/useOrderAddItemMutation';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import PickUpValue from '../DefineOrderFreeForm/OrderFreeItemsContent/OrderFreeItem/PickUpValue';
import { OrderItemPurchaseCheckbox } from '../DefineOrderFreeForm/OrderFreeItemsContent/OrderFreeItem/style';

const schema = yup.object().shape({
    description: yup.string().required(Translate.required)
});

const OrderAddItem = ({ id, closeModal, order }: any) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const { t } = useTranslation();
    const orderAddItemMutation = useOrderAddItemMutation();

    const onSubmit = useCallback(async (values: any) => {
        if (!values || !id) return;
        try {
            setLoading();
            const pickUpValue = (()=> {
                if(/:/gmi.test(values.pickUpValue)) {
                    const arr = values.pickUpValue.split(':');
                    return `${Number(arr[0])}:${arr[1]}`;
                }
                return Number(values.pickUpValue);
            })();
            const result = await orderAddItemMutation.mutateAsync({
                id,
                description: values.description,
                pickUpValue,
                address: values.address,
                addressJson: values?.addressObject,
                amount: Number(values?.amount?.replace(/\./gmi, '').replace(/,/gi, '.')),
                isPurchase: Number(values?.isPurchase)
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                await queryClient.invalidateQueries({ queryKey: ['get-current-drivers'] });
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    },[id, setLoading, resetLoading, orderAddItemMutation]);

    const handleCancel = useCallback(() => {
        closeModal();
    }, [closeModal]);

    useModalFormData({
        modalTitle: t(Translate.orderViewAddItemTitle, { dailyNumber: order.dailyNumber })
    });

    return (
        <OrderAddItemContainer>
            <Formik
                initialValues={{
                    isPurchase: false,
                    description: '',
                    address: '',
                    pickUpValue: '',
                    amount: ''
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {({values})=> {

                    console.log(values);

                    return (
                        <OrderAddItemForm>
                            <AddressSearch
                                isFormik
                                name={'address'}
                                label={Translate.defineOrderItemAddress}
                                showSwitch={false}
                                fullWidth
                            />
                            <OrderItemPurchaseCheckbox
                                name={'isPurchase'}
                                label={Translate.defineOrderItemIsPurchase}
                                type={'checkbox'}
                                fullWidth
                            />
                            <FormikTextField
                                name={'description'}
                                variant={'outlined'}
                                label={Translate.defineOrderItemDescription}
                                rows={3}
                                multiline
                                fullWidth
                            />
                            <PickUpValue fullWidth/>
                            <FormikInputCurrency
                                name={'amount'}
                                variant={'outlined'}
                                label={Translate.defineOrderItemAmount}
                                fullWidth
                            />
                            <OrderAddItemFooter>
                                <OrderAddItemButton isWhite onClick={handleCancel}>
                                    <Translation use={Translate.cancel} />
                                </OrderAddItemButton>
                                <OrderAddItemButton type="submit">
                                    <Translation use={Translate.save} />
                                </OrderAddItemButton>
                            </OrderAddItemFooter>
                        </OrderAddItemForm>
                    );
                }}
            </Formik>
        </OrderAddItemContainer>
    );

};

export default OrderAddItem;