import React, { useState } from 'react';
import OrdersView from './components/OrdersView';
import DefineOrderFreeForm from './components/modals/DefineOrderFreeForm';
import OrderFreeContextContainer from './components/modals/DefineOrderFreeForm/context';
import CustomSlide from '../../components/Transitions/Slide';


const Orders = () => {
    const [showForm, setShowForm] = useState<boolean>(false);


    return (
        <>
            {(!showForm || showForm === null) && (
               <CustomSlide>
                   <OrdersView setShowForm={setShowForm} />
               </CustomSlide>
            )}
            {showForm && (
                <CustomSlide>
                        <OrderFreeContextContainer>
                            <DefineOrderFreeForm showForm={showForm} setShowForm={setShowForm} />
                        </OrderFreeContextContainer>
                </CustomSlide>
            )}
        </>
    );
};

export default Orders;