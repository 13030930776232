import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Box, Typography } from '@mui/material';
import { Form } from 'formik';
import ButtonIcon from '../../../../../components/button/ButtonIcon';
import FormikCheckBox from '../../../../../components/Formik/FormikCheckBox';
import RenderLabelValue from '../../../../../components/Utils/RenderLabelValue';
import { LabelPart, ValuePart } from '../../../../../components/Utils/RenderLabelValue/style';

export const OrderFreeFormFrame = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const OrderFreeForm = styled(Form)`
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OrderFreeBackButton = styled(ButtonIcon)`
    &&& {
        position: absolute;
        top: 40px;
        left: -40px;
        color: white;
    }
`;

export const DefineOrderFreeFormContainer = styled(Flex)`
    min-width: 1200px;
    max-width: 1300px;
    min-height: 100%;
    max-height: 100%;
    flex-direction: column;
    padding: 5px 25px;
    position: relative;
`;

export const DefineOrderForm = styled(Flex)`
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 2;
    gap: 10px;
`;

export const DefineOrderColumn = styled(Flex)`
    flex-direction: column;
    width: 100%;
    gap: 15px;
`;

export const DefineOrderRow = styled(Flex)<{justify?: string, margin?: boolean}>`
    width: 100%;
    gap: 25px;
    
    ${props => props.justify && css`
        justify-content: ${props.justify};
    `}
    
    ${props => props.margin && css`
        margin-top: 10px;
    `}
    
    .MuiFormControl-root {
        min-width: 30%;
    }
    
    .customer-building-number {
        min-width: 150px;
        max-width: 150px;
    }
`;

export const DefineOrderSectionLabel = styled(Typography).attrs({
    variant: 'h6'
})`
    color: #FFF;
    text-align: left;
    font: normal 400 20px/25px 'Readex Pro';
`;

export const DefineOrderTime = styled(Flex)`
    position: relative;
`;

export const DefineOrderIsScheduled = styled(FormikCheckBox)`
    &&& {
        position: absolute;
        top: -35px;
        right: 0px; 
    }
`;

export const OrderDeliveryAmountPopoverContainer = styled(Flex)`
    padding: 25px;
    flex-direction: column;
    min-width: 400px;
    max-width: 550px;
`;

export const OrderDeliveryAmountPopoverCloseButton = styled(ButtonIcon)`
    &&& {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 26px;
        height: 26px;

        svg {
            color: white;
        }
    }
`;


export const OrderDeliveryAmountRenderLabelValue = styled(RenderLabelValue)`
    &&& {
        flex: 1;
        ${ValuePart}{
            width: auto;
        }
        
        ${LabelPart}{
            min-width: 120px !important;
            max-width: 205px !important;
        }
    }
`;