import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
    CancelButton,
    ConfirmButton,
    PreviewOrderItemEditPriceButtons,
    PreviewOrderItemEditPriceContainer,
    PreviewOrderItemEditPriceForm
} from './style';
import { Check, Clear } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import UiDataContext from '../../../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { queryClient } from '../../../../../../../core/root/ReactQueryProvider';
import PickUpValue from '../../../DefineOrderFreeForm/OrderFreeItemsContent/OrderFreeItem/PickUpValue';
import { Translate } from '../../../../../../../internationalization/translate';
import useUpdateOrderItemPickUpValueMutation
    from '../../../../../../../core/api/hooks/mutations/order/useUpdateOrderItemPickUpValueMutation';

const schema = yup.object().shape({
    pickUpValueNum: yup.number().nullable().test('pickUpValueNum', Translate.invalidField, (value) => value === undefined || value === null || value % 5 === 0),
    pickUpValueMask: yup.string().optional()
});


const PreviewOrderItemEditPickUpValue = ({ item, orderId, cancelEdit }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);

    const updateOrderItemPickUpValueMutation = useUpdateOrderItemPickUpValueMutation();
    const [active, setActive] = useState<string>('pickUpValueNum');

    const initValues = useMemo(() => {
        const isString = /:/gmi.test(item?.pickUpValue);
        let val;
        if (isString) {
            const arr = item?.pickUpValue.split(':');
            val = `${Number(arr[0])}:${arr[1]}`;
        } else {
            val = Number(item?.pickUpValue);
        }
        return{
                'pickUpValueNum': !isString ? val : '',
                'pickUpValueMask': isString ? val : ''
        };

    }, []);


    const onSubmit = useCallback(async (values: any, { setFieldError }: any) => {
        if (!values || !item?.id) return;
        if (!values?.pickUpValueNum && !values?.pickUpValueMask) {
            setFieldError(active, Translate.requiredField);
            return;
        }
        try {
            setLoading();
            const pickUpValue = (() => {
                const pickUpValue = values?.['pickUpValueNum'] ? values?.['pickUpValueNum'] :
                    values?.['pickUpValueMask'] ? values?.['pickUpValueMask'] : undefined;
                if (/:/gmi.test(pickUpValue)) {
                    const arr = pickUpValue.split(':');
                    return `${Number(arr[0])}:${arr[1]}`;
                }
                return Number(pickUpValue);
            })();

            const result = await updateOrderItemPickUpValueMutation.mutateAsync({
                id: item.id,
                pickUpValue
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({ queryKey: [`get-order-${orderId}`] });
                await queryClient.invalidateQueries({ queryKey: ['get-orders'] });
                cancelEdit();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [item, setLoading, resetLoading, updateOrderItemPickUpValueMutation, cancelEdit, active]);

    const handleCancel = useCallback(() => {
        cancelEdit();
    }, [cancelEdit]);

    return (
        <Formik
            initialValues={{
                ...initValues
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({ errors, values }) => {
                console.log(errors, values);
                return (
                    <PreviewOrderItemEditPriceForm>
                        <PreviewOrderItemEditPriceContainer>
                            <PickUpValue setActiveField={setActive} />
                            <PreviewOrderItemEditPriceButtons>
                                <ConfirmButton type="submit"><Check /></ConfirmButton>
                                <CancelButton onClick={handleCancel}><Clear /></CancelButton>
                            </PreviewOrderItemEditPriceButtons>
                        </PreviewOrderItemEditPriceContainer>
                    </PreviewOrderItemEditPriceForm>
                );
            }}
        </Formik>
    );
};

export default PreviewOrderItemEditPickUpValue;