import styled, { css } from 'styled-components';
import { Flex }        from 'assets/style';
import { Colors } from 'theme/palette';

export const LabelPart = styled(Flex).withConfig({
  shouldForwardProp: (prop) =>
      !['isUpperCase', 'isCenter', 'labelColor'].includes(prop),
})<{isUpperCase?: boolean, isCenter?: boolean, labelColor?: string}>`
  max-height: 20px;
  color: ${Colors.white};
  opacity:0.8;
  width: 100%;
  gap: 5px;
  
  ${props => props.isUpperCase && css`
    text-transform: uppercase;
  `}

  ${props => props.isCenter && css`
    justify-content: center;
  `}

  ${props => props?.labelColor && css`
    color: ${props.labelColor};
  `}
`;


export const RenderLabelValueContainer = styled(Flex).withConfig({
  shouldForwardProp: (prop) =>
      !['isRow'].includes(prop),
})<{ isRow?: boolean}>`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 15px;
  gap: 10px;

  ${props => props.isRow && css`
    flex-direction: row;
    
    ${LabelPart} {
      min-width: 140px;
      max-width: 180px;
      margin-right: 10px;
    }
  `}
  
`;



export const ValuePart = styled(Flex).withConfig({
  shouldForwardProp: (prop) =>
      !['isNotValue', 'align', 'valueColor'].includes(prop),
})<{isNotValue?: boolean, align?: 'center' | 'flex-start' | 'flex-end', valueColor?: string}>`
  color: ${Colors.white};
  font-size: inherit;
  width: 100%;
  justify-content: flex-start;
  
  ${props => props.isNotValue  && css`
    justify-content: center;
  `}
  
  ${props => props?.align && css`
    justify-content: ${props.align};
  `}
  ${props => props?.valueColor && css`
    color: ${props.valueColor};
  `}
`;

export const EmptyValue = styled.span`
  opacity: 0.4;
  text-align: center;
`;
