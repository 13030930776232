import React, { useCallback, useState } from 'react';
import FormikInputNumber from '../../../../../../../components/Formik/FormikInputNumber';
import { Translate } from '../../../../../../../internationalization/translate';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useFormikContext } from 'formik';
import FormikInputTimeMask from '../../../../../../../components/Formik/FormikInputTimeMask';

const PickUpValue = ({ id, name = 'pickUpValue', updateItem, setActiveField, ...rest }: any) => {
    const { setFieldValue } = useFormikContext() as any;
    const [state, setState] = useState<boolean>(false);

    const changeType = useCallback(() => {
        const field = `${id ? `${id}_` : ''}${name}`;
        setFieldValue(field, '', true);
        setState(v => {
            const dd = !v;
            setActiveField && setActiveField(`${field}${dd ? 'Mask': 'Num'}`);
            return dd;
        });
    }, [setState, setFieldValue, setActiveField, updateItem, name]);

    if (!state) {
        return (
            <FormikInputNumber
                name={`${id ? `${id}_` : ''}${name}Num`}
                variant={'outlined'}
                label={Translate.defineOrderItemPickUpValue}
                type={'number'}
                inputProps={{
                    step: '5'
                }}
                iconRight={{
                    icon: AccessTimeIcon,
                    handler: changeType,
                    tooltip: {
                        title: Translate.defineOrderItemPickUpValueUseTime
                    }
                }}
                {...rest}
            />
        );
    }


    return <FormikInputTimeMask
        name={`${id ? `${id}_` : ''}${name}Mask`}
        mask="00:00"
        placeholderChar={'_'}
        variant={'outlined'}
        label={Translate.defineOrderItemPickUpTimeValue}
        iconRight={{
            icon: HourglassTopIcon,
            handler: changeType,
            tooltip: {
                title: Translate.defineOrderItemPickUpValueUseValue
            }
        }}
        {...rest}
    />;
};

export default PickUpValue;