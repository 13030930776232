import { useMutation } from '@tanstack/react-query';
import axiosClient from '../../../config/axios';
import { createUrl } from '../../../utils/createUrl';

const useClearNotificationsMutation  = () => {
    const mutation = useMutation({
        mutationFn: async () => {
            const response = await axiosClient.put(createUrl({
                url: '/dispatcher/notifications-clear',
                version: 'v1',
            }));
            return response.data;
        }
    });
    return mutation;
};

export default useClearNotificationsMutation;