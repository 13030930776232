import styled, { css } from 'styled-components';
import { Step, StepLabel, Stepper } from '@mui/material';
import { Colors } from 'theme/palette';
import { Flex } from 'assets/style';


export const StepperContainer= styled(Stepper)`
    &&& {
        width: 100%;
        height: 60px;


        .MuiStepConnector-root {
            .MuiStepConnector-line {
              border-width: 3px;  
              border-color: ${Colors.lightGrey};
            }    
            
            &.Mui-active {
                .MuiStepConnector-line {
                    border-color: ${Colors.gradient.yellowDark};
                }
            }

            &.Mui-completed {
                .MuiStepConnector-line {
                    border-color: ${Colors.gradient.yellowDark};
                }
            }
        }
    }
`;

export const StepContainer = styled(Step)<{visible?: number}>`
  &&& {
    padding-right: 0;
    padding-left: 0;
      
      ${props => !props.visible && css`
        visibility: hidden;
      `}
  }
`;

export const StepperIconDiv = styled(Flex).withConfig({
    shouldForwardProp: (prop) =>
        !['active', 'completed'].includes(prop),
})<{active?: number, completed?: number}>`
    width: 60px;
    height: 60px;
    display: flex;
    background: ${Colors.lightGrey};
    justify-content: center;
    align-items: center;
    font: normal 400 18px/22.5px 'Readex Pro';
    border-radius: 100%;
    font-stretch: normal;
    color: #000000;
    
    ${props => !!props.active && css`
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
    `}
    
    ${props => !!props.completed && css`
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
    `}
`;

export const StepLabelContainer = styled(StepLabel).withConfig({
    shouldForwardProp: (prop) =>
        !['value', 'active'].includes(prop),
})<{ value?: number, active?: number}>`
  &&& {
    padding-right: 0;
    
    .MuiStepLabel-root {
      padding-right: 0;
    }

    .MuiStepLabel-iconContainer {
      padding-right: 0;
    }
    
    .MuiSvgIcon-root {
      width: 60px;
      height: 60px;
      display: flex;
      color: ${Colors.lightGrey};
      justify-content: center;
      align-items: center;
      //color: #000000;
      font: normal 400 18px/22.5px 'Readex Pro';
      border-radius: 100%;
      font-stretch: normal;  
        
      .MuiStepIcon-text {
         font-size: 8px;
         //font: normal 400 18px/22.5px 'Readex Pro';
         fill: #000000;
      }  

      &.Mui-active {
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
        color: ${Colors.gradient.yellowDark};
      }
      &.Mui-completed {
        color: ${Colors.gradient.yellowDark};
      }
    }
    
    
    ${props => props.value === props.active && css`
      > .MuiStepLabel-labelContainer {
        color: ${Colors.gradient.yellowDark};
      }
    `}
    
  }
`;
