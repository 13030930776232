import React from 'react';
import {useField} from 'formik';
import CheckBox from '../Checkbox';


const FormikCheckBox = ({name, handlerAfterChange, ...rest}: any) => {
    
    const [field, meta, helpers] = useField(name);

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched,
        helperText: Boolean(meta.touched) && meta.error
    };

    const onBlur = (e: any) => {
        field.onBlur(e);
    };

    const onChange = (e: any) => {
       helpers.setValue(e.target.checked);
       handlerAfterChange && handlerAfterChange(e.target.checked);
    };

    return (
        <CheckBox
            {...newProps}
            checked={newProps.value || false}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};


export default FormikCheckBox;
