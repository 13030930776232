import { SettingOrderContainer } from './style';
import { Translate } from '../../../../internationalization/translate';
import { useModal } from '../../../../hooks/useModal';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    selectSettingsBiddingAutoStartTime,
    selectSettingsBiddingTime,
    selectSettingsScheduledPendingTime
} from '../../../../store/app/appSlice';
import SettingsOrdersRow from '../../components/Orders/SettingsOrderRow';
import ChangeOrderBiddingTime from '../../components/Dialogs/ChangeOrderBiddingTime';
import ChangeOrderBiddingAutoStartTime from '../../components/Dialogs/ChangeOrderBiddingAutoStartTime';
import ChangeOrderScheduledPendingTime from '../../components/Dialogs/ChangeOrderScheduledPending';


const SettingsOrder = () => {

    const biddingTime = useSelector(selectSettingsBiddingTime);
    const biddingAutoStartTime = useSelector(selectSettingsBiddingAutoStartTime);
    const orderScheduledPendingTime = useSelector(selectSettingsScheduledPendingTime);

    const { openModal: openModalChangeBiddingTime, closeModal: closeModalChangeBiddingTime } = useModal({
        modal: ChangeOrderBiddingTime,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });


    const { openModal: openModalChangeBiddingAutoStartTime, closeModal: closeModalChangeBiddingAutoStartTime } = useModal({
        modal: ChangeOrderBiddingAutoStartTime,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });

    const { openModal: openModalChangeOrderScheduledPendingTime, closeModal: closeModalChangeOrderScheduledPendingTime } = useModal({
        modal: ChangeOrderScheduledPendingTime,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });



    const handleActionBiddingTime = useCallback(() => {
        openModalChangeBiddingTime({
            closeModal: closeModalChangeBiddingTime
        });
    }, [openModalChangeBiddingTime, closeModalChangeBiddingTime]);


    const handleActionBiddingAutoStartTime = useCallback(() => {
        openModalChangeBiddingAutoStartTime({
            closeModal: closeModalChangeBiddingAutoStartTime
        });
    }, [openModalChangeBiddingAutoStartTime, closeModalChangeBiddingAutoStartTime]);



    const handleActionOrderScheduledPendingTime = useCallback(() => {
        openModalChangeOrderScheduledPendingTime({
            closeModal: closeModalChangeOrderScheduledPendingTime
        });
    }, [openModalChangeOrderScheduledPendingTime, closeModalChangeOrderScheduledPendingTime]);


    return (
        <SettingOrderContainer>
            <SettingsOrdersRow label={Translate.settingsOrderBiddingTimeLabel} value={`${biddingTime} min`} handleAction={handleActionBiddingTime} />
            <SettingsOrdersRow label={Translate.settingsOrderBiddingAutoStartTimeLabel} value={`${biddingAutoStartTime} min`} handleAction={handleActionBiddingAutoStartTime} />
            <SettingsOrdersRow label={Translate.settingsOrderScheduledPendingTimeLabel} value={`${orderScheduledPendingTime} min`} handleAction={handleActionOrderScheduledPendingTime} />
        </SettingOrderContainer>
    );
};

export default SettingsOrder;