import React, { useCallback, useEffect } from 'react';
import { OrdersContainer, OrdersHeader, OrdersHeaderAddButton, OrdersHeaderType, OrdersViewsContainer } from '../style';
import Translation from '../../../components/Translation';
import { Translate } from '../../../internationalization/translate';
import AddIcon from '@mui/icons-material/Add';
import OrdersViewPart from './views/OrdersViewPart';
import OrdersDriverPart from './views/OrdersDriverPart';
import OrderViewAssignContextContainer from './views/context';
import { selectIsOrderStarted, setOrderStarted } from '../../../store/app/appSlice';
import { useAppDispatch } from '../../../store/hooks';
import { useSelector } from 'react-redux';


const OrdersView = ({ setShowForm }: any) => {

    const dispatch = useAppDispatch();
    const isOrderStarted = useSelector(selectIsOrderStarted);
    const handlerOpenDialogDefineOrder = useCallback(() => {
        setShowForm(true);
    }, [setShowForm]);

    useEffect(() => {
        const handleStorageChange = (event: any) => {
            if (event.key === 'ORDER_FORMIK') {
                dispatch(setOrderStarted(!!event.newValue));
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <OrdersContainer>
            <OrdersHeader>
                <Translation use={Translate.homePageTitle} as={OrdersHeaderType} />
                <OrdersHeaderAddButton
                    onClick={handlerOpenDialogDefineOrder}
                    startIcon={<AddIcon />}
                    isOrderStarted={+(!!isOrderStarted)}
                >
                    <Translation use={Translate.homeOrderAddButtonTitle} />
                </OrdersHeaderAddButton>
            </OrdersHeader>
            <OrdersViewsContainer>
                <OrderViewAssignContextContainer>
                    <OrdersViewPart />
                    <OrdersDriverPart />
                </OrderViewAssignContextContainer>
            </OrdersViewsContainer>
        </OrdersContainer>
    );
};

export default OrdersView;