import styled from 'styled-components';
import { Flex } from '../../../../assets/style';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Colors } from '../../../../theme/palette';


export const SettingsNavigationContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 260px;
    padding: 30px 0;
    border-right: 1px solid ${Colors.black};
`;



export const SettingsNavigationListItemButton = styled(ListItemButton)`
    &&& {
        padding: 12px 20px;
    }
`;


export const SettingsNavigationListItemText = styled(ListItemText)`
    &&& {
        margin: 0;
        
        .MuiTypography-root {
            color: ${Colors.black};
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: "Readex Pro";
        }
    }
`;
export const SettingsNavigationList = styled(Flex)`
    width: 100%;
    flex-direction: column;
    
    ${SettingsNavigationListItemButton} {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }    
`;


export const SettingsNavigationListItem = styled(ListItem)`
    &&& {
        &.Mui-selected {
            background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
            color: ${Colors.black};

            .MuiTypography-root {
                color: ${Colors.black};
            }
        }

    }
`;