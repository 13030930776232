import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';
import store from '../../../../store';
import { setVehicles } from '../../../../store/app/appSlice';


const useGetVehiclesQuery = (key = 'get-vehicles') => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: [key],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/vehicles',
        query: {
          active: true,
          perPage: 1000
        },
        version: 'v1'
      }));
      if (res?.data?.data && key === 'get-vehicles-all') {
        store.dispatch(setVehicles(res?.data?.data?.rows));
      }
      return res.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false
  });
  return { data, isLoading, isFetched, isFetching };
};

export default useGetVehiclesQuery;
