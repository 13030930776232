import { Flex } from '../../../../../../assets/style';
import styled from 'styled-components';


export const SettingsOrderStopPriceNoAddContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;

export const SettingsOrderPriceNoAddRange = styled(Flex)`
    min-width: 80px;
`;

export const SettingsOrderPriceNoAddPrice = styled(Flex)`

`;