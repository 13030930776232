import React, { useCallback, useEffect } from 'react';
import useGetApprovals from '../../../../core/api/hooks/query/approvals/useGetApprovals';
import {
    ApprovalListContent,
    ApprovalListHeader,
    ApprovalListHeaderCloseButton,
    ApprovalListHeaderTitle,
    ApprovalsListContainer
} from './style';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import ApprovalListItem from './ApprovalListItem';

const ApprovalsList = ({onClose, setRefetchCount, setTriggerRefetch, triggerRefetch}: any) => {

    const {t} = useTranslation();

    const { data, isFetched, refetch } = useGetApprovals();


    const setRefetch = useCallback(()=> {
        refetch();
        setRefetchCount();
    },[refetch, setRefetchCount]);

    useEffect(() => {
        if(!triggerRefetch) return;
        refetch();
        setTriggerRefetch(false);
    }, [triggerRefetch]);


    return (
        <ApprovalsListContainer>
            <ApprovalListHeader>
                <ApprovalListHeaderTitle>{`${t('notificationsLabel')} (${data?.length || 0})`}</ApprovalListHeaderTitle>
                <ApprovalListHeaderCloseButton onClick={onClose}>
                    <CloseOutlinedIcon />
                </ApprovalListHeaderCloseButton>
            </ApprovalListHeader>
            <ApprovalListContent>
               {isFetched && !data.length ?  <>No data</> :(data || []).map((item: any, index: number) => <ApprovalListItem key={index} item={item} setRefetch={setRefetch} />)}
            </ApprovalListContent>
        </ApprovalsListContainer>
    );
};

export default ApprovalsList;