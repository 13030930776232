import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';
import { Form } from 'formik';
import { SettingsDialogButtonsForm } from '../../../view/style';


export const ChangeOrderZoneForm = styled(Form)`
  min-width: 1150px;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
    
    ${SettingsDialogButtonsForm}{
        max-width: 65%;
    }
`;


export const ChangeOrderZoneContainer = styled(Flex)<{isRedZone?: boolean}>`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    min-width: 1200px;
    max-width: 1200px;
    min-height: 800px;
    max-height: 1000px;    
    
    ${({ isRedZone }) => isRedZone && css`
        min-width: 650px;
        max-width: 650px;
        min-height: auto;
        max-height: 100%;
        
        ${ChangeOrderZoneForm}{
            min-width: 600px;
            max-width: 600px;
        }
        
    `}
`;


export const ChangeOrderZoneTitle = styled(Typography).attrs({
    variant: 'h6'
})`
    &&& {
        text-align: center;
        margin-bottom: 15px;
    }
`;

export const ChangeOrderZoneFormContent = styled(Flex)`
    flex: 2;
    flex-direction: row;
    width: 100%;
    gap: 30px;
`;

export const ChangeOrderZonePricesContainer = styled(Flex)`
    flex-direction: column;
    flex: 1.4;
`;

export const ChangeOrderZoneMapContainer = styled(Flex)`
    flex-direction: column;
    flex: 2;
`;

export const ChangeOrderZoneFormRow = styled(Flex)`
    gap: 30px;
`;
