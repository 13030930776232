import React from 'react';
import { SettingsOrderZoneMapPriceContainer } from './style';
import { useSelector } from 'react-redux';
import {
    selectSettingsOrderZoneOne,
    selectSettingsOrderZoneThree,
    selectSettingsOrderZoneTwo
} from '../../../../../store/app/appSlice';
import SettingsZonePreview from './SettingsZonePreview';



const SettingsOrderZoneMapPrice = () => {
    const zone1 = useSelector(selectSettingsOrderZoneOne);
    const zone2 = useSelector(selectSettingsOrderZoneTwo);
    const zone3 = useSelector(selectSettingsOrderZoneThree);



    return (
        <SettingsOrderZoneMapPriceContainer>
            <SettingsZonePreview {...zone1} />
            <SettingsZonePreview {...zone2} />
            <SettingsZonePreview {...zone3} />
        </SettingsOrderZoneMapPriceContainer>
    );
};

export default SettingsOrderZoneMapPrice;