import { useCallback, useMemo, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import {
    PreviewShiftCollapseContainer,
    PreviewShiftCollapseText,
    PreviewShiftContainer,
    PreviewShiftRowButtonAction,
    PreviewShiftRowCollapseButton,
    PreviewShiftRowContainer,
    PreviewShiftRowItem,
    PreviewShiftRowStatus,
    PreviewShiftRowStatusText,
    PreviewShiftStatusText
} from './style';
import { TPreviewEndedShiftRow } from '../index';
import { formatPrice, formatTime } from '../../../../../../../../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { SHIFT_SCHEDULING_STATUS } from '../../../../../../view/SchedulingBoard/d';
import ConditionalRendering from '../../../../../../../../components/Utils/ConditionalRendering';
import PreviewShiftFinishPopoverButton from '../PreviewShiftFinishPopoverButton';
import { PreviewShiftRowStatusIndicator } from '../../PreviewShiftRow/style';
import Translation from '../../../../../../../../components/Translation';
import { Translate } from '../../../../../../../../internationalization/translate';
import { ORDER_STATUS } from '../../../../../../../Orders/d';
import moment from 'moment-timezone';

const PreviewShiftRow = ({
                             id,
                             driver,
                             status,
                             shiftEarnings,
                             shiftExpenses,
                             shiftStartAmount,
                             shiftEndAmount,
                             shiftSchedulingExpenses,
                             orders,
                             shiftDate,
                             shiftType,
                             startTime,
                             endTime,
                         }: TPreviewEndedShiftRow) => {
    const [open, setOpen] = useState<boolean>(false);

    const onClickOpen = useCallback(() => {
        setOpen(v => !v);
    }, [setOpen]);

    const [isEnded, isNeedDispEnd, isActive, statusText] = useMemo(() => [
        Number(status === SHIFT_SCHEDULING_STATUS.DISP_END),
        status === SHIFT_SCHEDULING_STATUS.END,
        status !== SHIFT_SCHEDULING_STATUS.END && status !== SHIFT_SCHEDULING_STATUS.DISP_END,
        (() => {
            switch (status) {
                case SHIFT_SCHEDULING_STATUS.END:
                    return Translate.driverEnd;
                case SHIFT_SCHEDULING_STATUS.PAUSE:
                    return Translate._pause;
                case SHIFT_SCHEDULING_STATUS.TEMP_STOP:
                    return Translate.driverTempEnd;
                case SHIFT_SCHEDULING_STATUS.TEMP_CAN_RESUME:
                    return Translate.driverTempCanResume;
                case SHIFT_SCHEDULING_STATUS.TEMP_NOT_WORK:
                    return Translate.driverTempNotWork;
                case SHIFT_SCHEDULING_STATUS.START:
                    return Translate.driverStart;
                default:
                    return Translate.driverNotStarted;
            }
        })()
    ], [status]);

    const [shiftExpensesText, ordersText, ordersAborted, ordersCustomerNotShow, completedCount, abortedCount, customerNotShowCount] = useMemo(() => {
        const completed = (orders || []).filter(x => x.status === ORDER_STATUS.COMPLETED);
        const aborted = (orders || []).filter(x => x.isAborted);
        const customerNotShowed = (orders || []).filter(x => x.isCustomerNotShow);
        return [
            (shiftSchedulingExpenses || [])?.map((x: any) => `${x.description} - ${x.value}`).join(', '),
            completed?.map((x: any) => `#${x.dailyNumber} - ${formatPrice(x.orderDeliveryAmount)}`).join(', '),
            aborted?.map((x: any) => `#${x.dailyNumber} - ${formatPrice(x.orderDeliveryAmount)}`).join(', '),
            customerNotShowed?.map((x: any) => `#${x.dailyNumber} - ${formatPrice(x.orderDeliveryAmount)}`).join(', '),
            completed?.length,
            aborted?.length,
            customerNotShowed?.length
        ];
    }, [shiftSchedulingExpenses, orders]);


    console.log(moment('2025-01-20T23:42:07.000Z').tz('Europe/Belgrade').toDate(), moment('2025-01-20T23:42:07.000Z').tz('Europe/Belgrade').format('DD.MM.YYYY HH:mm'));

    return (
        <PreviewShiftContainer isEnded={isEnded}>
            <PreviewShiftRowContainer>
                <PreviewShiftRowItem>{driver}</PreviewShiftRowItem>
                <PreviewShiftRowStatusText>{formatPrice(shiftStartAmount)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatusText>{formatPrice(shiftExpenses)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatusText>{formatPrice(shiftEarnings)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatusText>{formatPrice(shiftEndAmount)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatus>
                    <ConditionalRendering condition={!isActive}>
                        <PreviewShiftRowButtonAction>
                            <PreviewShiftRowCollapseButton onClick={onClickOpen}>
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </PreviewShiftRowCollapseButton>
                            <ConditionalRendering condition={isNeedDispEnd}>
                                <PreviewShiftFinishPopoverButton id={id} shiftDate={shiftDate} shiftType={shiftType} />
                            </ConditionalRendering>
                        </PreviewShiftRowButtonAction>
                    </ConditionalRendering>
                    <ConditionalRendering condition={isActive}>
                        <Translation use={statusText} as={PreviewShiftStatusText} />
                        <PreviewShiftRowStatusIndicator status={status} />
                    </ConditionalRendering>
                </PreviewShiftRowStatus>
            </PreviewShiftRowContainer>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <PreviewShiftCollapseContainer>
                    <div><PreviewShiftCollapseText>Počeo u:</PreviewShiftCollapseText>&nbsp;{formatTime(startTime, 'DD.MM.YYYY HH:mm')}</div>
                    <div><PreviewShiftCollapseText>Završio u:</PreviewShiftCollapseText>&nbsp;{formatTime(endTime, 'DD.MM.YYYY HH:mm')}</div>
                    <div></div>
                    <div><PreviewShiftCollapseText>Troškovi:</PreviewShiftCollapseText> {shiftExpensesText}</div>
                    <div><PreviewShiftCollapseText>Vožnje:</PreviewShiftCollapseText> {ordersText}</div>
                    <div><PreviewShiftCollapseText>Vožnje #:</PreviewShiftCollapseText> {completedCount}</div>
                    <div><PreviewShiftCollapseText>Odbijene:</PreviewShiftCollapseText> {ordersAborted}</div>
                    <div><PreviewShiftCollapseText>Odbijene #:</PreviewShiftCollapseText> {abortedCount}</div>
                    <div><PreviewShiftCollapseText>Mušterija se nije
                        pojavila:</PreviewShiftCollapseText> {ordersCustomerNotShow}</div>
                    <div><PreviewShiftCollapseText>Mušterija se nije
                        pojavila #:</PreviewShiftCollapseText> {customerNotShowCount}</div>
                </PreviewShiftCollapseContainer>
            </Collapse>
        </PreviewShiftContainer>

    );
};

export default PreviewShiftRow;