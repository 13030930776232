import React, { FC, useCallback, useMemo } from 'react';
import {
    Container,
    Content,
    EditButton,
    Header,
    PreviewLabel,
    PreviewValue, PurchaseContent, PurchaseRow, RangeText,
    RowPreviewContainer,
    Title
} from './style';
import EditIcon from '@mui/icons-material/Edit';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import { formatNumber, formatPrice } from '../../../../../../utils';
import { useModal } from '../../../../../../hooks/useModal';
import ChangeOrderZone from '../../../Dialogs/ChangeOrderZone';

interface PurchaseItem {
    price: number|string;
    from: number;
    to?: number;
    range?: string;
    id?: number;
}


type Props = {
    id: number
    radius?: number
    center?: any
    color: string
    fillColor: string
    popup: string
    zIndex: number;
    startPrice: number;
    startKmPrice: number;
    itemPrice: number;
    purchase: Record<number, PurchaseItem>
};


const SettingsZonePreview: FC<Props> = (zone: any) => {

    const [title, startPrice, startKmPrice, itemPrice, purchases] = useMemo(() => ([
        zone?.popup || '',
        zone?.startPrice || '',
        zone?.startKmPrice || '',
        zone?.itemPrice || '',
        Object.keys(zone?.purchase || {}).reduce((acc: PurchaseItem[], id: any) => {
            const value = zone.purchase[id] as PurchaseItem;
            return [
                ...acc,
                {

                    id,
                    range: `${formatNumber(value.from)}${value.to ? ` - ${formatNumber(value.to)}` : ' - ∞'}`,
                    price: formatPrice(value.price),
                    from: value.from,
                    to: value.to,
                }
            ];
        }, [] as PurchaseItem[])
    ]), [zone]);



    const { openModal: openModalChangeOrderZone, closeModal: closeChangeOrderZoneModal } = useModal({
        modal: ChangeOrderZone,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });


    const handleEditZone = useCallback(() => {
        openModalChangeOrderZone({
            closeModal: closeChangeOrderZoneModal,
            zoneId: zone?.id
        });
    },[zone, closeChangeOrderZoneModal, openModalChangeOrderZone]);




    return (
        <Container>
            <Header>
                <Translation use={title} as={Title} />
                <EditButton onClick={handleEditZone}>
                    <EditIcon />
                </EditButton>
            </Header>
            <Content>
                <RowPreviewContainer>
                    <Translation use={Translate.startPrice} as={PreviewLabel} />
                    <PreviewValue>{formatPrice(startPrice)}</PreviewValue>
                </RowPreviewContainer>
                <RowPreviewContainer>
                    <Translation use={Translate.startKmPrice} as={PreviewLabel} />
                    <PreviewValue>{formatPrice(startKmPrice)}</PreviewValue>
                </RowPreviewContainer>
                <RowPreviewContainer>
                    <Translation use={Translate.itemPrice} as={PreviewLabel} />
                    <PreviewValue>{formatPrice(itemPrice)}</PreviewValue>
                </RowPreviewContainer>
                <PurchaseContent>
                    <Translation use={Translate.purchaseRangePrice} as={PreviewLabel} />
                    {purchases?.length ? purchases.map(item => ((
                        <PurchaseRow key={item.id}>
                            <RangeText>{item.range}</RangeText>
                            <div>{item.price}</div>
                        </PurchaseRow>
                    ))) : null}
                </PurchaseContent>
            </Content>
        </Container>
    );
};

export default SettingsZonePreview;