import { ChangeOrderBiddingTimeContainer, ChangeOrderBiddingTimeForm, ChangeOrderBiddingTimeTitle } from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import { Colors } from '../../../../../theme/palette';
import useUpdateSettingsMutation from '../../../../../core/api/hooks/mutations/settings/useUpdateSettingsMutation';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import React, { useCallback, useContext, useMemo } from 'react';
import { SETTINGS_KEYS } from '../../../../../store/app/d';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import FormikInputTimeMask from '../../../../../components/Formik/FormikInputTimeMask';

const validationSchema = yup.object({
    biddingAutoStartTime: yup.string().required('required')
});

const ChangeOrderBiddingAutoStartTime = ({ closeModal }: any) => {
    const { t } = useTranslation();
    const settings = useSelector(selectSettings);
    const dispatch = useAppDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id, biddingAutoStartTime] = useMemo(() => {
        const sett = settings?.[SETTINGS_KEYS.BIDDING_AUTO_START_TIME];
        return [
            sett.id,
            sett?.value
        ];
    }, [settings]);
    const updateSettingsMutation = useUpdateSettingsMutation(id);


    const onSubmit = useCallback(async (values: any) => {
        if (!values?.biddingAutoStartTime) return;
        try {
            setLoading();
            const result = await updateSettingsMutation.mutateAsync({
                key: SETTINGS_KEYS.BIDDING_AUTO_START_TIME,
                value: values.biddingAutoStartTime
            });
            if (result?.status === 200) {
                if (result?.data) {
                    dispatch(setSettings({
                        [SETTINGS_KEYS.BIDDING_AUTO_START_TIME]: { ...(result?.data || {}) }
                    }));
                }
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        } finally {
            resetLoading();
        }
    }, [resetLoading, setLoading, updateSettingsMutation, closeModal, dispatch]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeOrderBiddingTimeContainer>
            <Formik
                initialValues={{
                    biddingAutoStartTime: biddingAutoStartTime
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <ChangeOrderBiddingTimeForm>
                    <Translation use={Translate.settingsOrderBiddingAutoStartTimeTitle} as={ChangeOrderBiddingTimeTitle} />
                    <FormikInputTimeMask
                        name={'biddingAutoStartTime'}
                        mask="00"
                        placeholderChar={'_'}
                        variant={'outlined'}
                        label={Translate.settingsOrderBiddingLabel}
                    />
                    <SettingsDialogButtonsForm isColumn={1}>
                        <SettingsDialogButton type={'submit'}>
                            <Translation use={Translate.save} />
                        </SettingsDialogButton>
                        <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                            <Translation use={Translate.cancel} />
                        </SettingsDialogButton>
                    </SettingsDialogButtonsForm>
                </ChangeOrderBiddingTimeForm>
            </Formik>

        </ChangeOrderBiddingTimeContainer>
    );
};

export default ChangeOrderBiddingAutoStartTime;