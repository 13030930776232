import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { SettingsOrdersRowContainer } from '../style';
import { Colors } from '../../../../../theme/palette';

export const SettingsOrderPricesContainer = styled(Flex)`
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-width: 360px;
    padding: 10px 0;
    

    ${SettingsOrdersRowContainer}{
        padding: 10px;
    }
    
`;

export const SettingsOrderNoAddressTitle = styled(Flex)`
    padding: 10px; 
    font: normal normal 400 20px/26.5px "Readex Pro";
    color: ${Colors.black};
`;


export const SettingsOrderPurchaseStopPriceNoAddressContent = styled(Flex)`
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;