import styled, { css } from 'styled-components';
import {
    FormControlLabel,
    Checkbox
}                             from '@mui/material';


export const CheckboxFormControl = styled(FormControlLabel).withConfig({
    shouldForwardProp: (prop) =>
        !['error', 'isDark'].includes(prop),
})<{error?: boolean, isDark?: boolean}>`
    &&& {
        color: white;
        margin-right: 0;
        width: 100%;
        margin-left: 0;

        ${props => !props.isDark && css`
            color: black;
            
            .MuiCheckbox-root {
                color: black;
            }
        `}
    }
`;

export const CheckBoxContainer = styled(Checkbox)`
  &&& {
    color: white;
    padding: 2px;
    margin-left: 5px;
    border-radius: 8px;
  }
`;
