import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';
import { Colors } from '../../../../../../theme/palette';


export const Container = styled(Flex)`
    flex-direction: column;
    flex: 1;
`;


export const Header =  styled(Flex)`
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 5px 0 10px;
`;


export const Title = styled.div`
    font: normal normal 400 18px/24.5px "Readex Pro";
    color: ${Colors.black};
`;

export const EditButton = styled(ButtonIcon)`
    &&& {
        background: ${Colors.black};
        color: ${Colors.white};
        width: 32px;
        height: 32px;
        padding: 4px;
        
        svg {
            width: 22px;
            height: 22px;
        }
    }
`;

export const Content = styled(Flex)`
    flex-direction: column;
    flex: 2;
    width: 100%;
    gap: 5px;
    padding: 0 10px;
`;


export const RowPreviewContainer = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
`;



export const PreviewLabel = styled(Flex)`
    font: normal normal 400 16px/22.5px "Readex Pro";
    color: ${Colors.black};
    min-width: 140px;
`;

export const PreviewValue = styled(Flex)`
    font: normal normal 400 16px/22.5px "Readex Pro";
    color: ${Colors.grey};
`;

export const PurchaseContent = styled(Flex)`
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;


export const PurchaseRow = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    font: normal normal 400 16px/22.5px "Readex Pro";
    color: ${Colors.grey};
`;

export const RangeText = styled(Flex)`
    min-width: 80px;
`;
