import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { OrderFreeFormFrame } from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { initialValuesOrderFree } from './validation';
import { Formik } from 'formik';
import * as yup from 'yup';
import OrderFreeContext from './context/context';
import useCreateOrderMutation from '../../../../../core/api/hooks/mutations/order/useCreateOrderMutation';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import moment from 'moment-timezone';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import { initialOrderItemRecord } from './context/d';
import { useSelector } from 'react-redux';
import { selectShiftDrivers } from '../../../../../store/app/appSlice';
import OrderFreeFormDefinition from './OrderFrom';
import { sleep } from '../../../../../utils';

const DefineOrderFreeForm = ({ setShowForm }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const { items, resetState, setFromStorage, setData } = useContext(OrderFreeContext);
    const shiftDrivers = useSelector(selectShiftDrivers);

    const storageData = localStorage.getItem('ORDER_FORMIK');
    const parsedData = (() => {
        try {
            const dd = storageData ? JSON.parse(storageData) : null;
            return dd ? {
                ...dd,
                orderDate: dd?.orderDate ? new Date(dd.orderDate as any) : new Date()
            } : {};
        } catch (e) {
            return {} as any;
        }
    })();

    useEffect(() => {
        if(localStorage.getItem('ORDER_REMOVE')) {
            localStorage.removeItem('ORDER_REMOVE');
            setFromStorage(false);
        }
    }, []);

    const [initValues, setInitValues] = useState({
        orderDate: new Date(),
        ...initialValuesOrderFree,
        ...(Object.assign({}, !parsedData ? (Object.keys(initialOrderItemRecord) || []).reduce((acc: any, x: any) => {
            return {
                ...acc,
                [`1720895846915_${x}`]: ''
            };
        }, {}): {}, parsedData || {} ))
    });

    useEffect(() => {
        if(!parsedData) return;
        const items = Object.keys(parsedData).filter((x: any) => /^\d/.test(x));
        if(!items.length) return;
        const itemsNew =  Object.keys(parsedData)?.filter((x: any) => /^\d/.test(x))?.reduce((acc, item) => {
            const match = item.match(/^(\d+)_/); // Extract the number before the underscore
            if (match) {
                const uniqueNumber = match[1];
                const field = item.substring(match[0].length);
                if (!acc[uniqueNumber]) {
                    acc[uniqueNumber] = {};
                }
                acc[uniqueNumber] = {
                    id: uniqueNumber,
                    ...acc[uniqueNumber],
                    [field]: parsedData[`${uniqueNumber}_${field}`]
                };
            }
            return acc;
        }, {} as any);
        setData({
            items: Object.keys(itemsNew).map((x:any) => itemsNew[x]),
        });
    }, []);

    const schemaDefault = yup.object().shape({
        customerPhone: yup.string().required(Translate.required),
        address: yup.string().required(Translate.required),
        orderDeliveryAmount: yup.string().required(Translate.required),
        orderDate: yup.string().required(Translate.required)
    });
    const [newValidationSchema, setNewValidationSchema] = useState(schemaDefault);

    const createOrderMutation = useCreateOrderMutation();

    const goBack = useCallback(() => {
        setShowForm(false);
        resetState();
    }, [setShowForm, resetState]);

    useModalFormData({
        modalTitle: 'defineOrderModalTitle'
    });

    const onSubmit = useCallback(async (values: any, {setSubmitting, setTouched, resetForm}: any) => {
        setTouched(Object.keys(values).reduce((acc, x) => ({
            ...acc,
            [x]: true
        }), {}));
        if (!values) {
            return;
        };
        setSubmitting(true);
        try {
            setLoading();
            const _items = items.map((x: any) => {
                const pickUpValue = (() => {
                    const pickUpValue = values?.[`${x.id}_pickUpValueNum`] ? values?.[`${x.id}_pickUpValueNum`] :
                        values?.[`${x.id}_pickUpValueMask`] ? values?.[`${x.id}_pickUpValueMask`] : undefined;
                    if (/:/gmi.test(pickUpValue)) {
                        const arr = pickUpValue.split(':');
                        return `${Number(arr[0])}:${arr[1]}`;
                    }
                    return Number(pickUpValue);
                })();
                return {
                    tempId: x.id,
                    description: values[`${x.id}_description`],
                    address: values[`${x.id}_address`],
                    addressJson: values?.[`${x.id}_addressObject`],
                    isPurchase: Number(values?.[`${x.id}_isPurchase`]),
                    pickUpValue,
                    amount: !isNaN(Number(values?.[`${x.id}_amount`])) ? Number(values?.[`${x.id}_amount`]) : void (0)
                };
            });
            const result = await createOrderMutation.mutateAsync({
                ...(pick(values, ['customerName', 'customerPhone', 'customerBuildingNumber', 'customerAddress', 'customerAddressJson']) || {}),
                customerAddress: values.address,
                customerAddressJson: values.addressObject,
                orderDate: moment(values?.orderDate).tz('Europe/Belgrade').format('YYYY-MM-DD'),
                orderDeliveryAmount: Number(values.orderDeliveryAmount?.replace(/\./gmi, '').replace(/,/gi, '.')),
                orderDeliveryAmountInfoCalculation: values?.orderDeliveryAmountInfoCalculation,
                orderTime: values?.orderTime ? moment(values?.orderTime).tz('Europe/Belgrade').format('HH:mm') : undefined,
                items: _items,
                shiftSchedulingId: shiftDrivers?.find((x: any) => x.driverId == values.driverId)?.shiftSchedulingId,
                driverId: values?.driverId ? values?.driverId : undefined
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({ queryKey: ['get-orders'] });
                await queryClient.invalidateQueries({ queryKey: ['get-current-drivers'] });
                localStorage.setItem('ORDER_REMOVE', '1');
                await sleep(500);
                resetForm();
                goBack();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
            setSubmitting(false);
        }
    }, [createOrderMutation, setLoading, resetLoading, goBack, items, shiftDrivers]);




    useEffect(() => {
        if (!items) return;
        let obj = {};
        const shiftsValidations = items.reduce((acc: any, x: any, index: number) => {
            const dd = Object.assign({
                [`${x.id}_description`]: yup.string().required(Translate.requiredField),
                [`${x.id}_pickUpValueNum`]: yup.number().nullable().test(`${x.id}_pickUpValue`, Translate.invalidField, (value) => value === undefined || value === null || value % 5 === 0)
            }, index === 0 ? {
                [`${x.id}_address`]: yup.string().required(Translate.requiredField)
            } : {});
            obj = {
                ...obj,
                ...dd
            };
            return acc.concat(
                yup.object().shape(dd));
        }, yup.object().shape({}));
        setNewValidationSchema(schemaDefault.concat(shiftsValidations as any));
        setInitValues(initialValuesOrderFree);
    }, [items]);

    return (
        <OrderFreeFormFrame>
            <Formik
                initialValues={initValues}
                validationSchema={newValidationSchema}
                onSubmit={onSubmit}
                validateOnBlur
                validateOnChange
            >
                <Suspense fallback={<div>Loading...</div>}>
                    <OrderFreeFormDefinition goBack={goBack}/>
                </Suspense>
            </Formik>
        </OrderFreeFormFrame>
    );
};

export default DefineOrderFreeForm;