import React, { useCallback, useContext, useMemo } from 'react';
import {
    ChangeOrderPurchaseStopPriceNoAddressContainer,
    ChangeOrderPurchaseStopPriceNoAddressForm,
    ChangeOrderPurchaseStopPriceNoAddressTitle
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import { Colors } from '../../../../../theme/palette';
import useUpdateSettingsMutation from '../../../../../core/api/hooks/mutations/settings/useUpdateSettingsMutation';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import { SETTINGS_KEYS } from '../../../../../store/app/d';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import PurchaseStopPriceRow from './PurchaseStopPriceRow';

const validationSchema = yup.object({
    'from_1': yup.string().required('required'),
    'to_1': yup.string().required('required'),
    'price_1': yup.string().required('required'),
    'from_2': yup.string().required('required'),
    'to_2': yup.string().required('required'),
    'price_2': yup.string().required('required'),
    'from_3': yup.string().required('required'),
    'to_3': yup.string().required('required'),
    'price_3': yup.string().required('required'),
    'from_4': yup.string().required('required'),
    'to_4': yup.string().required('required'),
    'price_4': yup.string().required('required'),
    'from_5': yup.string().required('required'),
    'to_5': yup.string().required('required'),
    'price_5': yup.string().required('required'),
    'from_6': yup.string().required('required'),
    'price_6': yup.string().required('required')
});

const ChangeOrderPurchaseStopPriceNoAddress = ({ closeModal }: any) => {
    const { t } = useTranslation();
    const settings = useSelector(selectSettings);
    const dispatch = useAppDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id, purchase] = useMemo(() => {
        const sett = settings?.[SETTINGS_KEYS.ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS];
        const data = sett.valueJson;
        return [
            sett.id,
            Object.keys(data).reduce((acc: any, curr) => {
                const val = data[curr];
                const obj = Object.assign({
                    [`id_${curr}`]: curr,
                    [`from_${curr}`]: `${val?.from}`,
                    [`price_${curr}`]: `${val?.price}`
                }, curr != '6' ? {
                    [`to_${curr}`]: `${val?.to}`
                } : {});
                return {
                    ...acc,
                    ...obj
                };
            }, {} as any)
        ];
    }, [settings]);

    const updateSettingsMutation = useUpdateSettingsMutation(id);

    const onSubmit = useCallback(async (values: any) => {
        if (!values) return;
        try {
            setLoading();

            const valueJson = Array.from({ length: 6 }, (_, x) => x+1).reduce((acc: any, x) => {
                return {
                    ...acc,
                    [x]: {
                        from:  Number(values[`from_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.')),
                        to:  Number(values[`to_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.')),
                        price:  Number(values[`price_${x}`]?.replace(/\./gmi, '').replace(/,/gi, '.'))
                    }
                };
            }, {});
            const result = await updateSettingsMutation.mutateAsync({
                key: SETTINGS_KEYS.ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS,
                valueJson
            });
            if (result?.status === 200) {
                if(result?.data) {
                    dispatch(setSettings({
                        [SETTINGS_KEYS.ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS]: {...(result?.data || {})}
                    }));
                }
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        } finally {
            resetLoading();
        }
    }, [resetLoading, setLoading, updateSettingsMutation, closeModal, dispatch]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeOrderPurchaseStopPriceNoAddressContainer>
            <Formik
                initialValues={{
                    ...purchase
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <ChangeOrderPurchaseStopPriceNoAddressForm>
                    <Translation use={Translate.settingsOrderPurchaseStopPriceChangeTitle}
                                 as={ChangeOrderPurchaseStopPriceNoAddressTitle} />

                    {Array.from({ length: 6 }, (_, x) => (
                        <PurchaseStopPriceRow key={x + 1} id={x + 1} />
                    ))}

                    <SettingsDialogButtonsForm >
                        <SettingsDialogButton type={'submit'}>
                            <Translation use={Translate.save} />
                        </SettingsDialogButton>
                        <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                            <Translation use={Translate.cancel} />
                        </SettingsDialogButton>
                    </SettingsDialogButtonsForm>
                </ChangeOrderPurchaseStopPriceNoAddressForm>
            </Formik>

        </ChangeOrderPurchaseStopPriceNoAddressContainer>
    );
};

export default ChangeOrderPurchaseStopPriceNoAddress;