import PreviewEndedShiftRow from './PreviewEndedShiftRow';
import PreviewEndedShiftRowHeader from './PreviewEndedShiftRowHeader';
import { PreviewEndedShiftContainer } from './style';

export type TPreviewEndedShiftRow = {
    id: string | number
    driver: string
    status: number
    shiftStartAmount: string
    shiftExpenses: string
    shiftEarnings: string
    shiftEndAmount: string
    shiftSchedulingExpenses?: any[]
    startTime?: Date,
    endTime?: Date,
    shiftDate: string,
    shiftType: string | number
    orders?: any[]
}

export type TPreviewEndedShiftProps = {
    dataPreview: TPreviewEndedShiftRow[]
}

const PreviewEndedShift = ({ dataPreview }: TPreviewEndedShiftProps) => {


    return (
        <PreviewEndedShiftContainer>
            <PreviewEndedShiftRowHeader />
            {dataPreview.map((x: any) => <PreviewEndedShiftRow key={x.id} {...x} />)}
        </PreviewEndedShiftContainer>
    );
};

export default PreviewEndedShift;